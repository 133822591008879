import { get, post, put } from '../xhr/index'

// POST a transaction record
export const postTransactionAPI = async (transactionDetail) => {
  const {
    team2ID,
    transactionType,
    requestStatusID,
    players,
  } = transactionDetail

  return post('/transactions', {
    team2ID: team2ID,
    transactionType: transactionType,
    requestStatusID: requestStatusID,
    players: players,
  })
}

// PUT Player-Team link
export const putPlayerTeamLinkAPI = async (teamID, playerID) => {
  return put('/transactions/movement', { teamID: teamID, playerID: playerID })
}

// Check if the player is still in FA
// (Meaning the player has not been taken by other team yet)
export const stillInFaAPI = async (playerID) => {
  return post('/transactions/inFa', {
    playerID: playerID,
  })
    .then((res) => {
      if (Object.keys(res.data).length) {
        return true
      } else {
        return false
      }
    })
    .catch(() => {
      return false
    })
}

// GET all the transactionHistory that are completed
export const transactionHistoryAPI = async (page, roesPerPage) => {
  // requestStatusID 6 = 'Completed'
  return get(`/transactions/${page}/${roesPerPage}`).then((res) => {
    return res.data
  })
}

export const transactionTotalNumberAPI = async () => {
  return get(`/transactions/completedTransaction/totalNumber`).then((res) => {
    return res.data[0].total
  })
}
