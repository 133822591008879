import React, { useState } from 'react'

// materialUI
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import CardMedia from '@material-ui/core/CardMedia'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

// Redux
import { useDispatch, useSelector } from 'react-redux'
import { fetchDraftData } from '../reducks/drafts/operations'
import { getUserInfo } from '../reducks/users/selector'
import { searchPlayerAPI } from '../adapters/playersAPI'
import { putPlayerPick } from '../adapters/draftAPI'

// Custom Components
import { Button, TextField } from './UIkits'

const DraftCard = (props) => {
  const selector = useSelector((state) => state)
  const dispatch = useDispatch()
  const userInfo = getUserInfo(selector)
  const { teamID, isAdmin } = userInfo

  const {
    draftTeamID,
    draftPickID,
    isSelected,
    logoURL,
    onClick,
    playerName,
    position,
    rank,
    setSelected,
    originalTeam,
    teamName,
    year,
  } = props

  const classes = useStyles()

  const [searchResult, setSearchResult] = useState([])

  return (
    <Card
      className={`${classes.root} ${isSelected && classes.selected} ${
        teamID === draftTeamID && classes.myTeam
      }`}
      raised={isSelected}
      onClick={onClick}
    >
      <CardHeader
        className={classes.header}
        title={rank}
        subheader={originalTeam}
      />
      <CardMedia
        component="img"
        alt={teamName}
        image={`https://res.cloudinary.com/tasteit/image/upload${logoURL}`}
        className={classes.logo}
      />

      {isSelected ? (
        <CardActions>
          <TextField
            label="Player"
            onChange={(e) => {
              searchPlayerAPI(5, e.target.value).then((res) => {
                setSearchResult(res)
              })
            }}
          />

          <div className={classes.searchResultDiv}>
            <List>
              {searchResult.map((player) => (
                <ListItem
                  className={classes.searchResultListItem}
                  onClick={() => {
                    putPlayerPick(draftPickID, player.playerID).then(() => {
                      dispatch(fetchDraftData(year))
                      setSelected(undefined)
                      setSearchResult([])
                    })
                  }}
                >
                  <ListItemText
                    primary={player.playerName}
                    secondary={`${player.position} in ${player.teamName}`}
                  />
                </ListItem>
              ))}
            </List>
          </div>
        </CardActions>
      ) : (
        <CardContent className={classes.content}>
          <Typography variant="h5" component="h3">
            {playerName}
          </Typography>
          <Typography variant="p" component="p">
            {position}
          </Typography>
        </CardContent>
      )}
    </Card>
  )
}

const useStyles = makeStyles(() => ({
  button: {
    position: 'absolute',
    zIndex: 3,
    right: '-70px',
  },
  content: {
    padding: '16px',
    textTransform: 'uppercase',
    width: '100%',
    justifyContent: 'space-between',
    '&:last-child': {
      padding: '16px',
    },
  },
  header: {
    '& .MuiCardHeader-content': {
      width: '80px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },

    '&.MuiCardHeader-title': {
      fontSize: '2rem',
    },
  },
  logo: {
    height: '80px',

    '&.MuiCardMedia-media': {
      width: 'auto',
    },
  },
  myTeam: {
    background: '#d4ebf2',
  },
  root: {
    alignItems: 'center',
    display: 'flex',
    margin: '20px 0',
    overflow: 'visible',
    position: 'relative',
    width: '100%',

    '&:hover': {
      cursor: 'pointer',
    },
  },
  searchResultDiv: {
    background: '#fff',
    boxShadow:
      'rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px',
    top: '80px',
    position: 'absolute',
    zIndex: 2,
  },
  searchResultListItem: {
    '&:hover': {
      background: '#0080ff',
      color: '#fff',
    },
  },
  // selected: { width: '100%' },
}))

export default DraftCard
