import React, { useState, useEffect } from 'react'
import moment from 'moment'
import axios from 'axios'

import { toast } from 'react-toastify'

import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'

import { BASE_URL } from '../../config/config'
import getJwt from '../../authentication/getJwt'

const useStyles = makeStyles({
  cardWrapper: {
    width: '100%',
    padding: '30px',
    margin: '10px',
    border: '1px solid black',
  },
  pendingMessage: {
    textAlign: 'center',
    color: 'green',
    margin: '30px 0',
  },
})

const AdminRequestCard = (props) => {
  const classes = useStyles()
  const jwt = getJwt()

  const {
    request,
    pendingRequestsNum,
    topValues,
    setPendingRequests,
    refresh,
    setRefresh,
  } = props

  const [values, setValues] = useState({
    request: '',
    response: '',
    requestDate: '',
  })

  useEffect(() => {
    // Format trade request
    const requestStatement = `${request.team1} have offered ${request.team1Player} for ${request.team2}'s ${request.team2Player}`

    // Format date
    const formattedDate = moment(request.createdAt).format('MM/DD/YYYY')

    setValues({
      ...values,
      request: requestStatement,
      requestDate: formattedDate,
    })
  }, [])

  const submitResponse = (requestStatus) => {
    // Check transaction validity
    axios
      .get(
        `${BASE_URL}/transactions/checkTransactionValidity/${request.transactionID}`
      )
      .then(async () => {
        // UPDATE transaction status
        await axios
          .put(
            `${BASE_URL}/transactions`,
            {
              transactionID: request.transactionID,
              requestStatus: requestStatus,
            },
            { headers: { Authorization: `${jwt}` } }
          )
          .then(() => {
            setPendingRequests({
              ...topValues,
              pendingRequests: pendingRequestsNum - 1,
            })
          })
          .catch((err) => {
            toast.error(err.message)
            setRefresh(!refresh)
            setPendingRequests({
              ...topValues,
              pendingRequests: pendingRequestsNum - 1,
            })
          })

        // UPDATE team player links(Complete the transaction)
        await axios.put(`${BASE_URL}/transactions/completeTransaction`, {
          transactionID: request.transactionID,
        })
      })
      .then(() => {
        setRefresh(!refresh)
      })
      .catch(() => {
        toast.error(
          'The transaction has been canceled by the system due to a confliction to other transactions.'
        )
        setRefresh(!refresh)
      })
  }

  return (
    <div className={classes.cardWrapper}>
      <Typography>
        {values.request} ({values.requestDate})
      </Typography>
      <Typography className={classes.pendingMessage}>
        <>
          <Button
            variant="contained"
            color="primary"
            onClick={() => submitResponse('Completed')}
          >
            Accept
          </Button>{' '}
          <Button
            variant="contained"
            onClick={() => submitResponse('Declined by Admin')}
          >
            Refuse
          </Button>
        </>
      </Typography>
    </div>
  )
}

export default AdminRequestCard
