export const FETCH_HELPERS = 'FETCH_HELPERS'
export const fetchHelpersAction = (state) => {
  return {
    type: 'FETCH_HELPERS',
    payload: {
      positions: state.positions,
      inTeamStatuses: state.inTeamStatuses,
    },
  }
}
