import Axios from 'axios'
import { BASE_URL } from '../../config/config'

import { get, put, post } from '../xhr/index'

function returnAxiosInstance() {
  return Axios.create()
}

// GET user info
export const userInfoAPI = async () => {
  return get('/users/info').then((res) => {
    return res.data
  })
}

// GET a number of pending requests for the notification
export const notificationAPI = async () => {
  // return get('/transactions/0').then((res) => {
  //   return res.data.filter((transaction) => {
  //     return (
  //       transaction.transactionType === 'Trade' &&
  //       transaction.requestStatusID === 2 // 'Pending Admin'
  //     )
  //   })
  // })

  const axios = returnAxiosInstance()
  return axios.get(`${BASE_URL}/transactions/0`).then((res) => {
    return res.data.filter((transaction) => {
      return (
        transaction.transactionType === 'Trade' &&
        transaction.requestStatusID === 2 // 'Pending Admin'
      )
    })
  })
}

// GET team list
export const teamListAPI = async () => {
  return get('/helpers/teams').then((res) => {
    return res.data
  })
}

// GET position list for the dropdown
export const positionListAPI = async () => {
  return get('/helpers/positions').then((res) => {
    return res.data
  })
}

// GET In-Team Status list for the dropdown
export const inTeamStatusListAPI = async () => {
  return get('/helpers/inTeamStatuses').then((res) => {
    return res.data
  })
}

// GET player by playerID
export const searchPlayerByIDAPI = async (playerID) => {
  return get(`/players/searchById/${playerID}`).then((res) => {
    return res.data
  })
}

// GET league status
export const leagueStatusAPI = async () => {
  return get('/leagues/status').then((res) => {
    return res.data
  })
}

// PUT player data
export const editPlayerAPI = async (
  playerID,
  dbId,
  position,
  playerName,
  gamesPlayed
) => {
  return put(`/players`, {
    playerID: playerID,
    profileURL: dbId,
    positionID: position,
    playerName: playerName,
    numberOfGames: gamesPlayed,
  }).then((res) => {
    return res.data
  })
}

// PUT league status
export const putLeagueStatusAPI = async (signings, trades) => {
  return put('/leagues/status', {
    signings: signings,
    trades: trades,
  }).then((res) => {
    return res.data
  })
}

// POST a new transaction for a new player
export const postTransactionAPI = async (playerID, teamID) => {
  return post(`/transactions/newPlayer`, {
    playerID: playerID,
    teamID: teamID,
  }).then((res) => {
    return res.data
  })
}
