import React, {useState} from 'react'
import {Link} from 'react-router-dom'

import {TextField, Button} from '../components/UIkits'
import {makeStyles} from '@material-ui/core/styles'
import MailOutlineIcon from '@material-ui/icons/MailOutline'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import {resetPasswordAPI} from '../adapters/usersAPI'


const ResetPassword = () => {
    const classes = useStyles()

    const [page, setPage] = useState(0)
    const [email, setEmail] = useState('')
    const onEmailChange = (e) => { setEmail(e.target.value) }
    const onSubmit = () => {
        resetPasswordAPI(email).then(()=>{
                setPage(2)
        })
    }

    return(
        <Container className={classes.wrapper}>
            <Container className={classes.formWrapper}>
            <MailOutlineIcon className={classes.emailIcon} />
            {
                page===0 ? (
                    <div className={classes.emailFormDiv}>
                        <TextField className={classes.emailField} label='Email Address' onChange={onEmailChange} value={email} />
                        <Button className={classes.button} onClick={onSubmit} variant='contained'>Send Reset Link</Button>
                    </div>
                ):(
                    <div className={classes.emailFormDiv}>
                        <Typography>If an account exists for '{email}', you'll receive an email with a reset password link.</Typography>
                        <Typography>Check your inbox and follow the reset password link.</Typography>
                    </div>
                )
            }
            <Link to='/'>Return to Sign In</Link>
            </Container>
        </Container>
    )
}

const useStyles = makeStyles({
    button: {
        margin: '20px 0 10px 0',
        width: '200px'
    },
    emailField: {
        width: '100%'
    },
    emailFormDiv: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
    },
    emailIcon: {
        width: '90px',
        height: '90px'
    },
    formWrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        padding: '50px 0',
        maxWidth: '60%',
        border: '5px solid black',
    },
    wrapper: {
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
})

export default ResetPassword