export const FETCH_PLAYERREQUEST = 'FETCH_PLAYERREQUEST'
export const fetchPlayerRequestAction = (state) => {
  return {
    type: 'FETCH_PLAYERREQUEST',
    payload: {
      count: state.count,
      list: state.list,
    },
  }
}
