// Libraries
import React, { useState, useEffect } from 'react'

// Material UI
import Typography from '@material-ui/core/Typography'

// Components
import PageTitle from '../../components/PageTitle'
import AdminRequestCard from '../../components/Admin/AdminRequestCard'
import Loading from '../../components/Loading'

// APIs
import { notificationAPI } from '../../adapters/adminAPI'

const AdminTradeRequests = (props) => {
  const { pendingRequests, topValues, setPendingRequests } = props

  const [values, setValues] = useState({
    tradeRequests: [],
    isLoading: false,
  })

  const [refresh, setRefresh] = useState(false)

  useEffect(async () => {
    setValues({ ...values, isLoading: true })

    // GET a list of requests
    const tradeRequests = await notificationAPI()

    setValues({
      ...values,
      tradeRequests: tradeRequests.reverse(),
      isLoading: false,
    })
  }, [refresh])

  return (
    <>
      {values.isLoading ? (
        <Loading />
      ) : (
        <>
          <PageTitle title="Trade Requests" />
          {values.tradeRequests.length === 0 ? (
            <Typography>There is no pending trade requests.</Typography>
          ) : (
            ''
          )}
          {values.tradeRequests.map((request) => {
            return (
              <AdminRequestCard
                key={request.transactionID}
                request={request}
                pendingRequestsNum={pendingRequests}
                topValues={topValues}
                setPendingRequests={setPendingRequests}
                refresh={refresh}
                setRefresh={setRefresh}
              />
            )
          })}
        </>
      )}
    </>
  )
}

export default AdminTradeRequests
