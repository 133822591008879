import { get, post } from '../xhr/index'

// GET user team info
export const userTeamDataAPI = async (teamID) => {
  return get(`/teams/info/other/${teamID}`).then((res) => {
    return res.data[0]
  })
}

// GET user roster info
export const userRosterDataAPI = async (teamID) => {
  return get(`/teams/players/other/${teamID}`).then((res) => {
    // Show in alphabetical order
    const playerList = res.data.all.sort((a, b) => {
      if (
        a.playerName.slice(a.playerName.lastIndexOf(' ') + 1) >
        b.playerName.slice(b.playerName.lastIndexOf(' ') + 1)
      ) {
        return 1
      } else {
        return -1
      }
    })

    return playerList
  })
}

// GET a list of taken players
export const takenPlayersDataAPI = async () => {
  return get('/teams/takenPlayerIDs').then((res) => {
    return res.data
  })
}

// GET league status
export const leagueStatusAPI = async () => {
  return get(`/leagues/status`).then((res) => {
    return res.data
  })
}

// POST a transaction record
export const postTransactionAPI = async (selectedTeamID, players) => {
  return post('/transactions', {
    team2ID: selectedTeamID.teamID, // the other team
    transactionType: 'Trade',
    requestStatusID: 1,
    players: players,
  })
    .then((res) => {
      return res.data
    })
    .catch((err) => {
      console.log(err)
    })
}
