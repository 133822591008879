import React from 'react'

import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

// Redux
import { useSelector } from 'react-redux'
import { getUserInfo } from '../reducks/users/selector'
import { getTeamData } from '../reducks/teams/selector'

import YourTeamTable from '../components/YourTeamTable'

const YourTeam = () => {
  const classes = useStyles()

  // Redux
  const selector = useSelector((state) => state)
  const teamData = getTeamData(selector)
  const userInfo = getUserInfo(selector)
  const { forwards, defenses, goalies, reserves, farms, drafts } = teamData
  const { firstName, lastName, email, teamName } = userInfo

  return (
    <>
      <Typography>Hey, {firstName}</Typography>
      <Typography>{teamName}</Typography>
      <Typography className={classes.metaInfo}>
        General Manager: {firstName} {lastName} {email}
      </Typography>

      <YourTeamTable title="Forwards" players={forwards} />
      <YourTeamTable title="Defense" players={defenses} />
      <YourTeamTable title="Goalies" players={goalies} />
      <YourTeamTable title="Reserves" players={reserves} />
      <YourTeamTable title="Farms" players={farms} />
      <div>
        <Typography className={classes.title}>Drafts</Typography>
        <ul className={classes.draftList}>
          {drafts.map((pick) => (
            <li key={pick.playerID}>{pick.playerName}</li>
          ))}
        </ul>
      </div>
    </>
  )
}

const useStyles = makeStyles({
  metaInfo: {
    borderTop: '1px solid black',
    borderBottom: '1px solid black',
    padding: '10px 0',
    margin: '10px 0 30px',
  },
  title: {
    textAlign: 'center',
  },
  draftList: {
    listStyle: 'none',
    padding: 0,
  },
})

export default YourTeam
