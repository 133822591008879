import React from 'react'

// materialUI
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { makeStyles } from '@material-ui/core/styles'

// Redux
import { useSelector } from 'react-redux'
import { getInTeamStatuses } from '../../reducks/helpers/selector'

const InTeamStatusesDropDown = (props) => {
  const { className, error, onChange, value, name } = props

  const classes = useStyles()

  // Redux
  const selector = useSelector((state) => state)
  const inTeamStatuses = getInTeamStatuses(selector)

  return (
    <FormControl
      variant="outlined"
      className={`${className} ${classes.outer}`}
      error={error}
    >
      <InputLabel id="dropdown-label">In-Team Status</InputLabel>
      <Select
        labelId="dropdown-label"
        id="dropdown"
        label="In-Team Status"
        name={name}
        onChange={onChange}
        value={value}
      >
        {inTeamStatuses.map((option) => (
          <MenuItem key={option.inTeamStatusID} value={option.inTeamStatusID}>
            {option.inTeamStatus}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>{error}</FormHelperText>
    </FormControl>
  )
}

const useStyles = makeStyles({
  outer: {
    minWidth: '120px',
  },
})

export default InTeamStatusesDropDown
