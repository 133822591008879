import { get, put } from '../xhr/index'

// GET user info
export const userDataAPI = async () => {
  return get(`/users/info`)
}

// GET transactions
export const transactionsAPI = async () => {
  return get(`/transactions/0`).then((res) => {
    return res.data
  })
}

// PUT transaction status
export const updateTransactionsAPI = async (transactionID, requestStatus) => {
  return put(`/transactions`, {
    transactionID: transactionID,
    requestStatus: requestStatus,
  })
}
