// Libraries
import React, { useState, useEffect } from 'react'

// Material UI
import FormControl from '@material-ui/core/FormControl'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Button from '@material-ui/core/Button'
import Switch from '@material-ui/core/Switch'
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert'
import { makeStyles } from '@material-ui/core/styles'

// Redux
import { useSelector, useDispatch } from 'react-redux'
import { rosterLimitsValues } from '../../reducks/leagues/selector'
import { fetchRosterLimits } from '../../reducks/leagues/operations'

// Components
import PageTitle from '../../components/PageTitle'
import { TextField } from '../../components/UIkits'

// APIs
import { leagueStatusAPI, putLeagueStatusAPI } from '../../adapters/leaguesAPI'

const useStyles = makeStyles({
  formWrapper: {
    margin: '50px 0',
  },
  limitInputsDiv: {
    display: 'flex',
    flexDirection: 'column',
    margin: '20px auto',
  },
  timeLimitInputsDiv: {
    display: 'flex',

    '&>span': {
      alignSelf: 'flex-end',
    },
  },
})

const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

const AdminLeagueManagement = () => {
  const classes = useStyles()
  const selector = useSelector((state) => state)
  const rosterLimits = rosterLimitsValues(selector)
  const dispatch = useDispatch()

  const [state, setState] = useState({
      signings: false,
      trades: false,
      defensesLimit: 0,
      farmsLimit: 0,
      forwardsLimit: 0,
      goaliesLimit: 0,
      reservesLimit: 0,
      timeLimit: 0,
    }),
    [open, setOpen] = useState(false)

  const handleChange = (event) => {
    if (event.target.name === 'signings' || event.target.name === 'trades') {
      setState({
        ...state,
        [event.target.name]: event.target.checked,
      })
    } else {
      setState({
        ...state,
        [event.target.name]: event.target.value,
      })
    }
  }

  const updateLeagueData = () => {
    putLeagueStatusAPI(state).then(() => {
      dispatch(fetchRosterLimits())
      setOpen(true)
    })
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setOpen(false)
  }

  useEffect(() => {
    leagueStatusAPI().then((res) => {
      setState({
        ...state,
        signings: res.signings,
        trades: res.trades,
        defensesLimit: rosterLimits.defensesLimit,
        farmsLimit: rosterLimits.farmsLimit,
        forwardsLimit: rosterLimits.forwardsLimit,
        goaliesLimit: rosterLimits.goaliesLimit,
        reservesLimit: rosterLimits.reservesLimit,
        timeLimit: rosterLimits.timeLimit,
      })
    })
  }, [])

  return (
    <>
      <PageTitle title="League Management" />

      <FormControl component="fieldset" className={classes.formWrapper}>
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                checked={state.signings}
                onChange={handleChange}
                name="signings"
              />
            }
            label="Freeze Signings"
          />
          <FormControlLabel
            control={
              <Switch
                checked={state.trades}
                onChange={handleChange}
                name="trades"
              />
            }
            label="Freeze Trades"
          />

          <div className={classes.limitInputsDiv}>
            <TextField
              label="Forwards Limit"
              name="forwardsLimit"
              onChange={handleChange}
              type="number"
              value={state.forwardsLimit}
            />

            <TextField
              label="Defenses Limit"
              name="defensesLimit"
              onChange={handleChange}
              type="number"
              value={state.defensesLimit}
            />

            <TextField
              label="Goalies Limit"
              name="goaliesLimit"
              onChange={handleChange}
              type="number"
              value={state.goaliesLimit}
            />

            <TextField
              label="Reserves Limit"
              name="reservesLimit"
              onChange={handleChange}
              type="number"
              value={state.reservesLimit}
            />

            <TextField
              label="Farms Limit"
              name="farmsLimit"
              onChange={handleChange}
              type="number"
              value={state.farmsLimit}
            />

            <div className={classes.timeLimitInputsDiv}>
              <TextField
                label="Time Limit"
                name="timeLimit"
                onChange={handleChange}
                type="number"
                value={state.timeLimit}
              />{' '}
              <span>hour(s)</span>
            </div>
          </div>
        </FormGroup>
      </FormControl>

      <Button onClick={updateLeagueData} variant="contained">
        Update
      </Button>

      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          League Status has been updated!
        </Alert>
      </Snackbar>
    </>
  )
}

export default AdminLeagueManagement
