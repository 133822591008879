// Libraries
import React, { useState, useEffect } from 'react'
import { Switch, Route, Link, useHistory } from 'react-router-dom'

// Material UI
import Container from '@material-ui/core/Container'
import Badge from '@material-ui/core/Badge'
import { makeStyles } from '@material-ui/core/styles'

// Redux
import { useDispatch, useSelector } from 'react-redux'
import { getUserInfo } from '../reducks/users/selector'
import { fetchUserInfo } from '../reducks/users/operations'
import { fetchTeamData } from '../reducks/teams/operations'
import { fetchRosterLimits } from '../reducks/leagues/operations'

// Components
import { Button, TeamDropDown } from '../components/UIkits'
import {
  Drafts,
  SignPlayers,
  OtherTeam,
  TradePlayers,
  TradeRequests,
  TransactionHistory,
  YourTeam,
} from '.'

import { AdminAddPlayers } from './Admin'

// APIs
import { notificationAPI } from '../adapters/dashboardAPI'
import { signoutAPI } from '../adapters/usersAPI'

const Dashboard = () => {
  const classes = useStyles()
  const history = useHistory()
  const dispatch = useDispatch()
  const selector = useSelector((state) => state)
  const userInfo = getUserInfo(selector)
  const { isAdmin } = userInfo

  const [values, setValues] = useState({
    pendingRequestsCount: 0,
  })

  // APIs
  useEffect(async () => {
    dispatch(fetchUserInfo())
    dispatch(fetchTeamData())
    dispatch(fetchRosterLimits())

    if (isAdmin) {
      history.push('/adminDashboard')
    }

    // GET the number of pending requests for the notification
    const pendingRequestsCount = await notificationAPI()

    setValues({
      ...values,
      pendingRequestsCount: pendingRequestsCount.data,
    })
  }, [])

  // When user click confirm/decline button on the request card,
  // this func will be called and minus the number of notification icon
  const minusPendingRequestsCount = () => {
    setValues({
      ...values,
      pendingRequestsCount: values.pendingRequestsCount - 1,
    })
  }

  return (
    <Container className={classes.outerWrapper}>
      {/* Nav Bar */}
      <Container className={`${classes.wrapper} ${classes.navBar}`}>
        <Link to="/dashboard" className={classes.link}>
          Your Team
        </Link>

        <Link to="/dashboard/signPlayers" className={classes.link}>
          Sign Players
        </Link>

        <Link to="/dashboard/tradePlayers" className={classes.link}>
          Trade Players
        </Link>

        <TeamDropDown className={classes.formControl} link />

        <Link to="/dashboard/transactionHistory" className={classes.link}>
          Transaction History
        </Link>

        <Badge badgeContent={values.pendingRequestsCount} color="primary">
          <Link to="/dashboard/tradeRequests" className={classes.link}>
            Trade Requests
          </Link>
        </Badge>

        <Link to="/dashboard/drafts" className={classes.link}>
          Drafts
        </Link>

        <Link to="/dashboard/addPlayer" className={classes.link}>
          Add Player
        </Link>

        <Button onClick={signoutAPI}>Log out</Button>
      </Container>

      <Container className={classes.wrapper}>
        <Switch>
          <Route exact path="/dashboard" component={YourTeam} />

          <Route exact path="/dashboard/signPlayers" component={SignPlayers} />

          <Route
            exact
            path="/dashboard/tradePlayers"
            component={TradePlayers}
          />

          <Route path="/dashboard/team" component={OtherTeam} />

          <Route
            exact
            path="/dashboard/transactionHistory"
            component={TransactionHistory}
          />

          <Route exact path="/dashboard/tradeRequests">
            <TradeRequests
              minusPendingRequestsCount={minusPendingRequestsCount}
            />
          </Route>

          <Route path="/dashboard/drafts" component={Drafts} />

          <Route path="/dashboard/addPlayer" component={AdminAddPlayers} />
        </Switch>
      </Container>
    </Container>
  )
}

const useStyles = makeStyles({
  outerWrapper: {
    display: 'flex',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px solid black',
    margin: '5px',
    padding: '20px',
  },
  navBar: {
    width: '400px',
    height: '500px',
    position: 'sticky',
    top: '0',
  },
  link: {
    margin: '10px 0',
  },
  formControl: {
    margin: 10,
    minWidth: 120,
  },
})

export default Dashboard
