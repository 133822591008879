import React from 'react'
import Button from '@material-ui/core/Button'

const CustomButton = (props) => {
  const { variant, color, type, children, className, onClick, disabled } = props
  return (
    <Button
      variant={variant}
      color={color}
      type={type}
      className={className}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </Button>
  )
}

export default CustomButton
