import React from 'react'
import { Route, Switch, Redirect } from 'react-router'
import getJwt from './authentication/getJwt'

import { Dashboard, ResetPassword, SetPassword, SignIn, SignUp } from './pages'
import { AdminDashboard } from './pages/Admin'
import AuthenticationComp from './authentication/AuthenticationComp'

const Router = () => {
  const token = getJwt()

  return (
    <Switch>
      <Route exact path="(/)?">
        {token ? <Redirect to="/dashboard" /> : <SignIn />}
      </Route>

      <Route exact path="/signup" component={SignUp} />
      <Route exact path="/reset_password" component={ResetPassword} />
      <Route path="/password/reset/:token" component={SetPassword} />

      <AuthenticationComp>
        <Route path="/dashboard" component={Dashboard} />
        <Route path="/adminDashboard" component={AdminDashboard} />
      </AuthenticationComp>
    </Switch>
  )
}

export default Router
