import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  line: {
    width: '600px',
    height: 0,
    borderBottom: '1px solid black',
  },
})

const Line = () => {
  const classes = useStyles()

  return (
    <>
      <div className={classes.line} />
    </>
  )
}

export default Line
