// Libraries
import React, { useState } from 'react'
import { Link, useHistory } from 'react-router-dom'

// Material UI
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import { makeStyles } from '@material-ui/core/styles'

// Redux
import { useDispatch, useSelector } from 'react-redux'
import { getUserInfo } from '../reducks/users/selector'
import { fetchUserInfo } from '../reducks/users/operations'
import { fetchTeamData } from '../reducks/teams/operations'

// Components
import { Button, PasswordField, TextField } from '../components/UIkits'

// API
import { signinAPI } from '../adapters/usersAPI'

const SignIn = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()
  const selectors = useSelector((state) => state)

  const [userName, setUserName] = useState(''),
    [password, setPassword] = useState('')

  // Login
  const handleSubmit = async (e) => {
    e.preventDefault()

    await signinAPI(userName, password).then((res) => {
      if (!res) {
        console.log(res)
        return false
      }

      const isAdmin = res.data[0].is_admin

      if (isAdmin) {
        history.push('/adminDashboard')
      } else {
        history.push('/dashboard')
      }

      return true
    })
  }

  return (
    <Container className={classes.wrapper}>
      <Typography variant="h1" className={classes.h1}>
        Welcome to Ultimate Fantasy Hockey!
      </Typography>

      <Container className={classes.formWrapper}>
        <Typography>Please log in to use your account.</Typography>

        <form
          noValidate
          autoComplete="off"
          className={classes.form}
          onSubmit={handleSubmit}
        >
          {/* UserID Input */}
          <TextField
            className={classes.root}
            label="User Name"
            value={userName}
            onChange={(e) => setUserName(e.target.value)}
          />

          {/* Password Input */}
          <PasswordField
            className={classes.root}
            label="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />

          <Button
            variant="contained"
            color="primary"
            type="submit"
            className={classes.button}
          >
            Log In
          </Button>
        </form>

        <Typography>
          Don&apos;t have an account? Create an account{' '}
          <Link to="/signup">here</Link>.
        </Typography>
        <Typography>
          <Link to="/reset_password">Forgot Password?</Link>
        </Typography>
      </Container>
    </Container>
  )
}

const useStyles = makeStyles({
  wrapper: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  h1: {
    fontSize: '2.5rem',
    marginBottom: '30px',
  },
  formWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    padding: '50px 0',
    maxWidth: '60%',
    border: '5px solid black',
  },
  form: {
    display: 'flex !important',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '30px 0',
  },
  root: {
    width: '100%',
  },
  button: {
    marginTop: '30px',
  },
})

export default SignIn
