// Libraries
import React, { useEffect, useState } from 'react'

// Material UI
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import Search from '@material-ui/icons/Search'
import Divider from '@material-ui/core/Divider'

// Redux
import { useDispatch, useSelector } from 'react-redux'
import { isSigningSuspended } from '../reducks/leagues/selector'
import { getLeagueStatus } from '../reducks/leagues/operations'

// Components
import SignPlayerTable from '../components/SignPlayerTable'
import PageTitle from '../components/PageTitle'

// APIs
import { searchPlayerAPI } from '../adapters/playersAPI'

const SignPlayer = () => {
  const classes = useStyles()

  // Redux
  const dispatch = useDispatch()
  const selector = useSelector((state) => state)
  const signingSuspended = isSigningSuspended(selector)
  const [searchResult, setSearchResult] = useState([])

  const searchPlayer = async (event) => {
    if (event.target.value.length > 0) {
      // GET players searching by keyword
      const searchResults = await searchPlayerAPI(10, event.target.value)

      setSearchResult(searchResults)
    }
  }

  useEffect(() => {
    dispatch(getLeagueStatus())
  }, [])

  return (
    <div>
      <PageTitle title="Sign Player" />

      {signingSuspended ? (
        <Typography className={classes.errorMessage}>
          Signing is suspended by Admin.
        </Typography>
      ) : (
        <>
          <div className={classes.searchWrapper}>
            <Typography>Enter player name:</Typography>

            <TextField
              id="outlined-basic"
              className={classes.searchForm}
              variant="outlined"
              onChange={searchPlayer}
              disabled={signingSuspended}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
          </div>

          <Divider variant="middle" />

          <SignPlayerTable searchResults={searchResult} />
        </>
      )}
    </div>
  )
}

const useStyles = makeStyles({
  searchWrapper: {
    margin: '10px 0',
    padding: '10px 0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  searchForm: {
    marginLeft: '10px',
  },
  errorMessage: {
    color: 'red',
    margin: '100px 0',
  },
})

export default SignPlayer
