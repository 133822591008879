import { get, put, post } from '../xhr/index'

// POST a new draft
export const postDraft = async (year, winner) => {
  return post('/drafts', {
    year: year,
    winner: winner,
  })
    .then((res) => {
      return res.data
    })
    .catch((err) => {
      throw err.response.data.message
    })
}

// POST a new draft pick
export const postDraftPick = async (draftID, round1, round2, round3) => {
  return post('/drafts/pick', {
    draftID: draftID,
    round1: round1,
    round2: round2,
    round3: round3,
  }).then((res) => {
    return res.data
  })
}

// GET draft data
export const getDraft = async (year) => {
  return get(`/drafts/${year}`).then((res) => {
    return res.data
  })
}

// PUT a draft pick to add player
export const putPlayerPick = async (draftPickID, playerID) => {
  return put('/drafts/pick', {
    draftPickID: draftPickID,
    playerID: playerID,
  }).then((res) => {
    return res.data
  })
}

// PUT a draft pick to add player
export const deleteDraft = async (draftID) => {
  return put('/drafts/deleteDraft', {
    draftID: draftID,
  }).then((res) => {
    return res.data
  })
}
