import React from 'react'

import Typography from '@material-ui/core/Typography'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Divider from '@material-ui/core/Divider'
import { makeStyles } from '@material-ui/core/styles'

import { Button, TeamDropDown } from './UIkits'

const DraftEditor = (props) => {
  const classes = useStyles()
  const {
    round,
    name,
    value,
    updateTeam,
    updateOriginalTeam,
    error,
    setValues,
    values,
  } = props

  const addRow = () => {
    setValues({ ...values, [name]: value.concat([0]) })
  }

  const deleteRow = () => {
    const newDraftOrder = value.filter((v, index) => {
      return index < value.length - 1
    })
    setValues({ ...values, [name]: newDraftOrder })
  }

  return (
    <div className={classes.outerDiv}>
      <Typography align="center" variant="h5" component="h2">
        Round {round}
      </Typography>
      <Typography align="center" variant="p" component="p" color="secondary">
        {error}
      </Typography>

      <div className={classes.listDiv}>
        <List className={classes.list}>
          {value.map((draft, index) => (
            <ListItem className={classes.listItem}>
              <ListItemText primary={index + 1} />

              <TeamDropDown
                className={classes.dropdown}
                label="From"
                name={name}
                onChange={(e) => {
                  updateOriginalTeam(index, e.target.value, name)
                }}
                value={draft.originalTeam}
              />

              <TeamDropDown
                className={classes.dropdown}
                name={name}
                onChange={(e) => {
                  updateTeam(index, e.target.value, name)
                }}
                value={draft.team}
              />
            </ListItem>
          ))}
        </List>

        <div>
          <Button
            variant="contained"
            color="primary"
            onClick={addRow}
            className={classes.button}
          >
            Add Row
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={deleteRow}
            className={classes.button}
            disabled={value.length === 1}
          >
            Delete Row
          </Button>
        </div>
      </div>

      <Divider />
    </div>
  )
}

const useStyles = makeStyles({
  button: {
    margin: '20px',
    width: '120px',
    whiteSpace: 'nowrap',
  },
  dropdown: {
    margin: '0 20px',
    minWidth: '200px',
  },
  outerDiv: {
    margin: '50px 0',
  },
  list: {
    width: '100%',
  },
  listDiv: {
    margin: '40px 0',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  listItem: {
    width: '100%',
  },
})

export default DraftEditor
