export const FETCH_DRAFTS = 'FETCH_DRAFTS'
export const fetchDraftsAction = (draftState) => {
  return {
    type: 'FETCH_DRAFTS',
    payload: {
      draftID: draftState.draft.draftID,
      winner: {
        teamName: draftState.draft.teamName,
        logoURL: draftState.draft.logoURL,
      },
      draftList: [
        { round: 1, list: draftState.draftList.round1 },
        { round: 2, list: draftState.draftList.round2 },
        { round: 3, list: draftState.draftList.round3 },
      ],
    },
  }
}
