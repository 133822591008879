import Axios from 'axios'

import getJwt from '../../authentication/getJwt'
import { BASE_URL } from '../../config/config'

const returnAxiosInstance = () => {
  return Axios.create()
}

export const get = async (url) => {
  const axios = returnAxiosInstance()
  const jwt = getJwt()

  return axios.get(BASE_URL + url, {
    headers: { Authorization: `${jwt}` },
  })
}

export const post = async (url, requestData) => {
  const axios = returnAxiosInstance()
  const jwt = getJwt()

  return axios.post(BASE_URL + url, requestData, {
    headers: { Authorization: `${jwt}` },
  })
}

export const put = async (url, requestData) => {
  const axios = returnAxiosInstance()
  const jwt = getJwt()

  return axios.put(BASE_URL + url, requestData, {
    headers: { Authorization: `${jwt}` },
  })
}
