// Libraries
import React from 'react'

// MaterialUI
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import Link from '@material-ui/core/Link'
import Typography from '@material-ui/core/Typography'
import Launch from '@material-ui/icons/Launch'
import IconButton from '@material-ui/core/IconButton'

// Redux
import { useSelector } from 'react-redux'
import { rosterLimitsValues } from '../reducks/leagues/selector'

// Others
import { ihdbBaseURL } from '../config/config'

const YourTeamTable = (props) => {
  const classes = useStyles()
  const selector = useSelector((state) => state)
  const rosterLimits = rosterLimitsValues(selector)

  const { isRegTeam, title, players } = props

  let capacityNumber

  switch (title) {
    case 'Forwards':
      capacityNumber = rosterLimits.forwardsLimit
      break
    case 'Defense':
      capacityNumber = rosterLimits.defensesLimit
      break
    case 'Goalies':
      capacityNumber = rosterLimits.goaliesLimit
      break
    case 'Reserves':
      capacityNumber = rosterLimits.reservesLimit
      break
    case 'Farms':
      capacityNumber = rosterLimits.farmsLimit
      break
    default:
  }

  return (
    <div className={classes.wrapper}>
      <Typography
        className={`${classes.title} ${
          players.length > capacityNumber && classes.error
        }`}
      >
        {title}
        {isRegTeam && ` (${players.length}/${capacityNumber})`}
      </Typography>

      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="roster table">
          <TableHead>
            <TableRow>
              <TableCell>Player</TableCell>
              <TableCell>Games</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {players.map((player) => (
              <TableRow key={player.playerID}>
                <TableCell component="th" scope="row">
                  <Link
                    href={`${ihdbBaseURL}${player.profileURL}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <IconButton>
                      <Launch className={classes.launchIcon} />
                    </IconButton>
                    {player.playerName}
                  </Link>
                </TableCell>
                <TableCell>{player.numberOfGames}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}

const useStyles = makeStyles({
  wrapper: {
    margin: '20px 0',
  },
  title: {
    margin: '20px 0',
    textAlign: 'center',
  },
  table: {
    minWidth: 650,
  },
  launchIcon: {
    marginRight: '10px',
  },
  deleteIcon: {
    color: 'red',
    cursor: 'pointer',
  },
  error: {
    color: 'red',
  },
})

export default YourTeamTable
