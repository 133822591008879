import { get } from '../xhr/index'

// GET team roster
export const rosterAPI = () => {
  return get('/teams/players')
}

// GET selected team info
export const selectedTeamDataAPI = async (teamID) => {
  return get(`/teams/info/other/${teamID}`).then((res) => {
    return res.data[0]
  })
}

// GET selected team's roster info
export const selectedRosterDataAPI = async (teamID) => {
  return get(`/teams/players/other/${teamID}`).then((res) => {
    return res.data
  })
}
