import { fetchUserInfoAction } from './actions'
import { userInfoAPI } from '../../adapters/usersAPI'

export const fetchUserInfo = () => {
  return async (dispatch) => {
    await userInfoAPI().then(async (res) => {
      const userInfo = res.data[0]
      await dispatch(fetchUserInfoAction(userInfo))
    })
  }
}
