import { getDraft } from '../../adapters/draftAPI'
import { fetchDraftsAction } from './actions'

export const fetchDraftData = (year) => {
  return (dispatch) => {
    return getDraft(year).then((res) => {
      dispatch(fetchDraftsAction(res))
    })
  }
}
