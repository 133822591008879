import { get, put } from '../xhr/index'

// GET league status
export const leagueStatusAPI = async () => {
  return get(`/leagues/status`).then((res) => {
    return res.data
  })
}

// GET roster limits
export const getRosterLimits = async () => {
  return get(`/leagues/rosterLimits`).then((res) => {
    return res.data[0]
  })
}

// PUT league status
export const putLeagueStatusAPI = async (state) => {
  return put('/leagues/status', state).then((res) => {
    return res.data
  })
}
