import React, { useState, useEffect } from 'react'

// Material UI
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import Search from '@material-ui/icons/Search'

// Components
import PageTitle from '../../components/PageTitle'
import SignPlayerTable from '../../components/SignPlayerTable'
import Line from '../../components/Line'

// APIs
import { searchPlayerAPI } from '../../adapters/playersAPI'

const useStyles = makeStyles({
  outerDiv: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  searchWrapper: {
    margin: '10px 0',
    padding: '10px 0',
    display: 'flex',
    alignItems: 'center',
  },
  searchForm: {
    marginLeft: '10px',
  },
})

const AdminPlayersList = () => {
  const classes = useStyles()

  const [values, setValues] = useState({
    searchResults: [],
    searchWord: '',
    searchTrigger: true,
  })

  const searchPlayer = async (event) => {
    if (event.target.value.length > 0) {
      // GET players searching by keyword
      const searchResults = await searchPlayerAPI(10, event.target.value)

      setValues({
        ...values,
        searchWord: event.target.value,
        searchResults: searchResults,
      })
    }
  }

  useEffect(async () => {
    const searchResults = await searchPlayerAPI(10, values.searchWord)

    setValues({
      ...values,
      searchResults: searchResults,
    })
  }, [values.searchTrigger])

  return (
    <div className={classes.outerDiv}>
      <PageTitle title="Player List" />

      <div className={classes.searchWrapper}>
        <Typography>Enter player name:</Typography>
        <TextField
          id="outlined-basic"
          className={classes.searchForm}
          variant="outlined"
          onChange={searchPlayer}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
        />
      </div>
      <Line />

      <SignPlayerTable
        searchResults={values.searchResults}
        admin="true"
        trigger={values}
        setTrigger={setValues}
      />
    </div>
  )
}

export default AdminPlayersList
