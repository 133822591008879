// Libraries
import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import Modal from 'react-modal'
import { toast } from 'react-toastify'

// MaterialUI
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'

// Redux
import { useSelector } from 'react-redux'
import { getUserInfo } from '../reducks/users/selector'

// Components
import TradePlayersTable from '../components/TradePlayersTable'
import PageTitle from '../components/PageTitle'
import Loading from '../components/Loading'
import { TeamDropDown } from '../components/UIkits'

// APIs
import {
  userTeamDataAPI,
  userRosterDataAPI,
  takenPlayersDataAPI,
  postTransactionAPI,
  leagueStatusAPI,
} from '../adapters/tradePlayersAPI'

Modal.setAppElement('#root')

const useStyles = makeStyles({
  searchWrapper: {
    margin: '10px 0',
    padding: '10px 0',
    display: 'flex',
    alignItems: 'center',
  },
  tableWrapper: {
    display: 'flex',
  },
  formControl: {
    margin: 10,
    minWidth: 120,
  },
  button: {
    margin: '2rem',
  },
  errorMessage: {
    color: 'red',
    margin: '100px 0',
  },
})

const modalStyle = {
  overlay: {
    position: 'fixed',
    zIndex: '1',
    top: 0,
    left: 0,
    backgroundColor: 'rgba(0,0,0,0.85)',
  },
  content: {
    position: 'absolute',
    top: '20rem',
    left: '20rem',
    right: '20rem',
    bottom: '20rem',
    backgroundColor: 'white',
    padding: '1.5rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
}

const TradePlayer = () => {
  const classes = useStyles()
  const history = useHistory()

  const selector = useSelector((state) => state)
  const userInfo = getUserInfo(selector)

  const [values, setValues] = useState({
    selectedTeamID: '',
    yourTeamInfoData: userInfo,
    yourRosterData: [],
    otherTeamInfoData: [],
    otherRosterData: [],
    takenPlayers: [],
    isLoadingTable: false,
    modalIsOpen: false,
    isLoading: false,
  })

  const [tradePlayers, setTradePlayers] = useState([])

  const [isSigningSuspended, setIsSigningSuspended] = useState(false)

  // GET your team info and roster
  useEffect(async () => {
    setValues({ ...values, isLoading: true })

    const yourRosterData = await userRosterDataAPI(userInfo.teamID)

    const takenPlayers = await takenPlayersDataAPI()

    setValues({
      ...values,
      yourTeamInfoData: userInfo,
      yourRosterData: yourRosterData,
      takenPlayers: takenPlayers,
      isLoading: false,
    })
  }, [])

  // GET other team info and roster
  useEffect(async () => {
    setValues({ ...values, isLoadingTable: true })

    const otherTeamInfoData = await userTeamDataAPI(
      values.selectedTeamID.teamID
    )

    const otherRosterData = await userRosterDataAPI(
      values.selectedTeamID.teamID
    )

    setValues({
      ...values,
      otherTeamInfoData: otherTeamInfoData,
      otherRosterData: otherRosterData,
      isLoadingTable: false,
    })
  }, [values.selectedTeamID])

  // Dropdown handler
  const handleChange = (e) => {
    const { value } = e.target
    const team = JSON.parse(value)

    setValues({
      ...values,
      selectedTeamID: team,
      isLoading: false,
    })

    // Leave only selected user's players in tradePlayers array
    console.log(values.yourTeamInfoData)
    setTradePlayers(
      tradePlayers.filter((player) => {
        return player.teamID === values.yourTeamInfoData.teamID
      })
    )
  }

  const validation = () => {
    let goSign = false

    // Check if the user picked at least one player or draft ticket from each team
    console.log(tradePlayers)
    for (let i = 0; i < tradePlayers.length; i++) {
      if (tradePlayers[0].teamID !== tradePlayers[i].teamID) {
        goSign = true
      }
    }

    if (!goSign) {
      toast.error('Pick at least one player or draft ticket from each team!')
    } else {
      setValues({ ...values, modalIsOpen: true })
    }
  }

  // Submit request
  const submitRequest = (selectedTeamID, players) => {
    postTransactionAPI(selectedTeamID, players).then(history.push('/dashboard'))
  }

  useEffect(() => {
    leagueStatusAPI().then((res) => {
      setIsSigningSuspended(res.trades)
    })
  }, [])

  return (
    <>
      <PageTitle title="Trade Players" />

      {isSigningSuspended ? (
        <Typography className={classes.errorMessage}>
          Trading is suspended by Admin.
        </Typography>
      ) : (
        <>
          <div className={classes.searchWrapper}>
            <Typography>Choose a team to trade with:</Typography>

            <TeamDropDown
              className={classes.formControl}
              onChange={handleChange}
              value={values.selectedTeamID}
            />
          </div>

          <div className={classes.tableWrapper}>
            {values.isLoading ? (
              <Loading />
            ) : (
              <>
                {/* Your Team Table */}
                <TradePlayersTable
                  teamInfoData={values.yourTeamInfoData}
                  rosterData={values.yourRosterData}
                  tradePlayers={tradePlayers}
                  setTradePlayers={setTradePlayers}
                  takenPlayers={values.takenPlayers}
                />

                {/* The Other Team Table */}
                {values.selectedTeamID === '' || values.isLoadingTable ? (
                  <></>
                ) : (
                  <TradePlayersTable
                    teamInfoData={values.otherTeamInfoData}
                    rosterData={values.otherRosterData}
                    tradePlayers={tradePlayers}
                    setTradePlayers={setTradePlayers}
                    takenPlayers={values.takenPlayers}
                  />
                )}
              </>
            )}
          </div>
          <Button variant="contained" color="primary" onClick={validation}>
            Request Trade
          </Button>
        </>
      )}

      <Modal isOpen={values.modalIsOpen} style={modalStyle}>
        <Typography>You are about to send a trade request.</Typography>

        <div>
          <Button
            variant="contained"
            onClick={() => submitRequest(values.selectedTeamID, tradePlayers)}
            type="button"
            className={classes.button}
          >
            OK
          </Button>
          <Button
            variant="contained"
            onClick={() =>
              setValues({
                ...values,
                modalIsOpen: false,
              })
            }
            type="button"
            className={classes.button}
          >
            Cancel
          </Button>
        </div>
      </Modal>
    </>
  )
}

export default TradePlayer
