import { getInactivePlayers } from '../../adapters/playersAPI'
import { fetchPlayerRequestAction } from './actions'

export const fetchPlayerRequest = () => {
  return async (dispatch) => {
    await getInactivePlayers().then((res) => {
      const data = { count: res.length, list: res }

      dispatch(fetchPlayerRequestAction(data))
    })
  }
}
