import {
  createStore as reduxCreateStore,
  combineReducers,
  applyMiddleware,
} from 'redux'
import thunk from 'redux-thunk'

import { connectRouter, routerMiddleware } from 'connected-react-router'

import { DraftsReducer } from '../drafts/reducers'
import { HelpersReducer } from '../helpers/reducers'
import { LeaguesReducer } from '../leagues/reducers'
import { TeamsReducer } from '../teams/reducers'
import { UsersReducer } from '../users/reducers'
import { PlayerRequestsReducer } from '../playerRequests/reducers'

const createStore = (history) => {
  return reduxCreateStore(
    combineReducers({
      drafts: DraftsReducer,
      helpers: HelpersReducer,
      leagues: LeaguesReducer,
      router: connectRouter(history),
      teams: TeamsReducer,
      users: UsersReducer,
      playerRequests: PlayerRequestsReducer,
    }),
    applyMiddleware(routerMiddleware(history), thunk)
  )
}

export default createStore
