import { getPositions, getInTeamStatuses } from '../../adapters/helperAPI'
import { fetchHelpersAction } from './actions'

export const fetchHelpers = () => {
  return async (dispatch) => {
    const positions = await getPositions()
    const inTeamStatuses = await getInTeamStatuses()

    return dispatch(
      fetchHelpersAction({
        positions: positions,
        inTeamStatuses: inTeamStatuses,
      })
    )
  }
}
