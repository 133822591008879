import { fetchRosterAction } from './actions'
import { rosterAPI } from '../../adapters/teamsAPI'

export const fetchTeamData = () => {
  return async (dispatch) => {
    await rosterAPI().then((res) => {
      const roster = res.data

      dispatch(fetchRosterAction(roster))
    })
  }
}
