import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import { makeStyles } from '@material-ui/core/styles'

import { validateSignup } from '../service/validateInfo'
import { signupForm as useForm } from '../service/useForm'

import {
  TextField,
  PasswordField,
  TeamDropDown,
  Button,
} from '../components/UIkits'

const SignUp = ({ submitForm }) => {
  const classes = useStyles()
  const history = useHistory()

  const {
    handleChange,
    handleSubmit,
    values,
    setValues,
    errors,
    updateTeam,
  } = useForm(submitForm, validateSignup)

  useEffect(async () => {
    if (prompt('Enter your passcode.') !== '1994') {
      history.push('/')
    }
  }, [])

  return (
    <Container className={classes.wrapper}>
      <Typography variant="h1" className={classes.h1}>
        Welcome to Ultimate Fantasy Hockey!
      </Typography>

      <Container className={classes.formWrapper}>
        <Typography>Create an account</Typography>

        <form
          noValidate
          autoComplete="off"
          className={classes.form}
          onSubmit={handleSubmit}
        >
          {/* First Name */}
          <TextField
            label="First Name"
            name="firstName"
            className={classes.root}
            value={values.firstName}
            onChange={handleChange}
            error={errors.firstName}
            helperText={errors.firstName}
          />

          {/* Last Name */}
          <TextField
            label="Last Name"
            name="lastName"
            className={classes.root}
            value={values.lastName}
            onChange={handleChange}
            error={errors.lastName}
            helperText={errors.lastName}
          />

          {/* User Name */}
          <TextField
            label="User name"
            name="userName"
            value={values.userName}
            onChange={handleChange}
            className={classes.root}
            error={errors.userName}
            helperText={errors.userName}
          />

          {/* Team Dropdown */}
          <TeamDropDown
            className={classes.root}
            error={errors.teamID}
            onChange={(e) => updateTeam(e)}
            value={values.teamID}
            name="teamID"
          />

          {/* Email Address */}
          <TextField
            label="Email"
            name="email"
            value={values.email}
            onChange={handleChange}
            className={classes.root}
            error={errors.email}
            helperText={errors.email}
          />

          {/* Password */}
          <PasswordField
            className={classes.root}
            label="Password"
            name="password"
            value={values.password}
            onChange={handleChange}
            error={errors.password}
          />

          {/* Confirm Password */}
          <PasswordField
            className={classes.root}
            label="Confirm Password"
            name="password2"
            value={values.confirmPassword}
            onChange={handleChange}
            error={errors.password2}
          />

          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            type="submit"
          >
            Sign Up
          </Button>
        </form>
      </Container>
    </Container>
  )
}

const useStyles = makeStyles({
  wrapper: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  h1: {
    fontSize: '2.5rem',
    marginBottom: '30px',
  },
  formWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    padding: '50px 0',
    maxWidth: '60%',
    border: '1px solid black',
  },
  form: {
    display: 'flex !important',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '30px 0',
  },
  root: {
    width: '100%',
    margin: '10px 0',
  },
  button: {
    marginTop: '30px',
  },
})

export default SignUp
