import { createSelector } from 'reselect'

const helpersSelector = (state) => state.helpers

export const getPositions = createSelector(
  [helpersSelector],
  (state) => state.positions
)

export const getInTeamStatuses = createSelector(
  [helpersSelector],
  (state) => state.inTeamStatuses
)
