// Libraries
import React from 'react'

// Components
import PageTitle from '../components/PageTitle'
import TransactionHistoryTable from '../components/TransactionHistoryTable'

const TransactionHistory = () => (
  <>
    <PageTitle title="Transaction History" />
    <TransactionHistoryTable />
  </>
)

export default TransactionHistory
