// Libraries
import React, { useState, useEffect } from 'react'
import moment from 'moment'

// MaterialUI
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'

// Components
import Loading from './Loading'

// APIs
import { transactionHistoryAPI } from '../adapters/transactionsAPI'

const columns = [
  { id: 'date', label: 'Date', minWidth: 170 },
  {
    id: 'movement',
    label: 'Movement',
    minWidth: 170,
    format: (value) => value.toLocaleString('en-US'),
  },
]

const TransactionHistoryTable = () => {
  const classes = useStyles()

  const [values, setValues] = useState({
    page: 0,
    rowsPerPage: 10,
    transactions: [],
    allTransactionsCount: 0,
    isLoading: false,
  })

  useEffect(async () => {
    setValues({ ...values, isLoading: true })

    // GET all the transactionHistory that are completed
    const transactionHistory = await transactionHistoryAPI(
      values.page,
      values.rowsPerPage
    )

    // Format all the transactionHistory
    const formattedTransactionHistory = []

    for (let i = 0; i < transactionHistory.length; i++) {
      const formattedDate = moment(transactionHistory[i].updatedAt).format(
        'MM/DD/YYYY'
      )

      switch (transactionHistory[i].transactionType) {
        case 'Release':
          formattedTransactionHistory.push({
            id: transactionHistory[i].transactionID,
            date: formattedDate,
            movement: `${transactionHistory[i].team1Player} released to Waivers by ${transactionHistory[i].team1}`,
            backgroundColor: '#99FE98',
          })
          break
        case 'Sign':
          if (transactionHistory[i].team2Player !== null) {
            formattedTransactionHistory.push({
              id: transactionHistory[i].transactionID,
              date: formattedDate,
              movement: `${transactionHistory[i].team2Player} signed by ${transactionHistory[i].team1} from Free Agent`,
              backgroundColor: '#99CD00',
            })
          } else {
            formattedTransactionHistory.push({
              id: transactionHistory[i].transactionID,
              date: formattedDate,
              movement: `${transactionHistory[i].team1Player} signed by ${transactionHistory[i].team1}`,
              backgroundColor: '#99CD00',
            })
          }

          break
        case 'Trade':
          formattedTransactionHistory.push({
            id: transactionHistory[i].transactionID,
            date: formattedDate,
            movement: `${transactionHistory[i].team1} trade ${transactionHistory[i].team1Player} to ${transactionHistory[i].team2} for ${transactionHistory[i].team2Player}`,
            backgroundColor: '#35CCCC',
          })
          break
        default:
          break
      }
    }

    setValues({
      ...values,
      transactions: formattedTransactionHistory.reverse(),
      allTransactionsCount: formattedTransactionHistory.length,
      isLoading: false,
    })
  }, [values.page, values.rowsPerPage])

  const handleChangePage = (event, newPage) => {
    setValues({ ...values, page: newPage })
  }

  const handleChangeRowsPerPage = (event) => {
    setValues({ ...values, page: 0, rowsPerPage: +event.target.value })
  }

  return (
    <>
      {values.isLoading ? (
        <Loading />
      ) : (
        <Paper className={classes.root}>
          <TableContainer className={classes.container}>
            <Table stickyHeader aria-label="transaction history table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {values.transactions
                  .slice(
                    values.page * values.rowsPerPage,
                    values.page * values.rowsPerPage + values.rowsPerPage
                  )
                  .map((row) => (
                    <TableRow
                      tabIndex={-1}
                      key={row.id}
                      style={{ background: row.backgroundColor }}
                    >
                      {columns.map((column) => {
                        const value = row[column.id]
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {column.format && typeof value === 'number'
                              ? column.format(value)
                              : value}
                          </TableCell>
                        )
                      })}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={values.allTransactionsCount}
            rowsPerPage={values.rowsPerPage}
            page={values.page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
      )}
    </>
  )
}

const useStyles = makeStyles({
  root: {
    width: '100%',
    margin: '40px 0',
  },
  container: {
    maxHeight: 440,
  },
  cell__trade: {
    background: 'red',
  },
})

export default TransactionHistoryTable
