import * as Actions from './actions'
import initialState from '../store/initialState'

export const PlayerRequestsReducer = (
  state = initialState.playerRequests,
  action
) => {
  switch (action.type) {
    case Actions.FETCH_PLAYERREQUEST:
      return {
        ...state,
        ...action.payload,
      }
    default:
      return state
  }
}
