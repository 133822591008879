import { useParams, useLocation } from 'react-router-dom'
import {Button, PasswordField} from '../components/UIkits'
import {makeStyles} from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import { validateSetNewPassword } from '../service/validateInfo'
import { setNewPasswordForm as useForm } from '../service/useForm'

const SetPassword = ({submitForm}) => {
    const classes = useStyles()
    const token = useParams().token
    const email = useLocation().search.replace('?email=','').replace('%40', '@')

    const {
        handleChange,
        handleSubmit,
        values,
        errors,
    } = useForm(submitForm, validateSetNewPassword, email, token)

    return(
        <Container className={classes.wrapper}>
            <Container className={classes.formWrapper}>
                <PasswordField
                    className={classes.field} label='New Password' name="password" value={values.password}
                    onChange={handleChange} error={errors.password} />
                <PasswordField
                    className={classes.field} label='Confirm New Password' name="confirmPassword" value={values.confirmPassword}
                    onChange={handleChange} error={errors.confirmPassword} />
                <Button className={classes.button} onClick={handleSubmit} variant='contained'>Reset</Button>
            </Container>
        </Container>
    )
}

const useStyles = makeStyles({
    button: {
        margin: '20px 0 10px 0',
        width: '100px'
    },
    field: {
        margin: '10px 0'
    },
    formWrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        padding: '50px 0',
        maxWidth: '60%',
        border: '5px solid black',
    },
    wrapper: {
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
})

export default SetPassword