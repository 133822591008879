import React, { useState, useEffect } from 'react'

import { toast } from 'react-toastify'

import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import FormControl from '@material-ui/core/FormControl'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import Checkbox from '@material-ui/core/Checkbox'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  formControl: {
    margin: theme.spacing(3),
  },
  teamName: {
    textAlign: 'center',
  },
  formWrapper: {
    border: '1px solid black',
    padding: '20px',
  },
}))

export default function CheckboxesGroup(props) {
  const classes = useStyles()

  const {
    teamInfoData,
    rosterData,
    tradePlayers,
    setTradePlayers,
    takenPlayers,
  } = props

  console.log(teamInfoData)

  const [rosterChecklist, setRosterChecklist] = useState([])

  // Load all the players in the selected team
  useEffect(() => {
    const newList = rosterChecklist
    for (let i = 0; i < rosterData.length; i++) {
      setRosterChecklist(
        newList.push({
          [rosterData[i].playerName]: false,
        })
      )
    }
  }, [])

  const handleChange = (event) => {
    // Check box on/off
    setRosterChecklist[event.target.index] = {
      [event.target.name]: event.target.checked,
    }

    // Add/Remove players in tradePlayers
    if (event.target.checked) {
      const result = tradePlayers.concat([
        {
          teamID: teamInfoData.teamID,
          playerID: event.target.name,
        },
      ])

      setTradePlayers(result)
    } else {
      const result = tradePlayers.filter((item) => {
        return item.playerID !== event.target.name
      })

      setTradePlayers(result)
    }
  }

  const takenPlayerFeedback = () => {
    toast.error('The player is in other pending request')
  }

  return (
    <div className={classes.root}>
      <FormControl
        // error={error}
        component="fieldset"
        className={classes.formControl}
      >
        <Typography className={classes.teamName}>
          {teamInfoData ? teamInfoData.teamName : ''}
        </Typography>
        <FormGroup className={classes.formWrapper}>
          {rosterData.map((player, index) => {
            if (takenPlayers.indexOf(player.playerID) === -1) {
              return (
                <FormControlLabel
                  key={player.playerID}
                  control={
                    <Checkbox
                      checked={rosterChecklist[index]}
                      onChange={handleChange}
                      name={`${player.playerID}`}
                      index={index}
                      key={player.playerID}
                    />
                  }
                  label={player.playerName}
                />
              )
            } else {
              return (
                <FormControlLabel
                  key={player.playerID}
                  onClick={takenPlayerFeedback}
                  control={
                    <Checkbox
                      disabled
                      checked={rosterChecklist[index]}
                      name={`${player.playerID}`}
                      index={index}
                      key={player.playerID}
                    />
                  }
                  label={player.playerName}
                />
              )
            }
          })}
        </FormGroup>
        <FormHelperText>
          Pick at least one player or draft ticket
        </FormHelperText>
      </FormControl>
    </div>
  )
}
