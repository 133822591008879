// Libraries
import React, { useState, useEffect } from 'react'

// Redux
import { useSelector } from 'react-redux'
import { getUserInfo } from '../reducks/users/selector'

// Components
import PageTitle from '../components/PageTitle'
import RequestCard from '../components/RequestCard'
import Loading from '../components/Loading'

// APIs
import { transactionsAPI } from '../adapters/tradeRequestsAPI'

const TradeRequests = (props) => {
  const selector = useSelector((state) => state)
  const userInfo = getUserInfo(selector)

  const { minusPendingRequestsCount } = props

  const [values, setValues] = useState({
    teamID: '',
    tradeRequests: [],
    pendingRequestsNum: 0,
    isLoading: false,
  })

  useEffect(async () => {
    setValues({ ...values, isLoading: true })
    const { teamID } = userInfo

    // GET transactions
    const transactions = await transactionsAPI()

    // Filter all the transactions by 'Trade'
    const tradeRequests = transactions.filter((transaction) => {
      return transaction.transactionType === 'Trade'
    })

    // Filter all the transactions by 'Pending by the GM'
    const pendingRequests = transactions.filter((transaction) => {
      return (
        transaction.transactionType === 'Trade' &&
        transaction.requestStatusID === 1 &&
        transaction.team2ID === values.teamID
      )
    })

    setValues({
      ...values,
      teamID: teamID,
      tradeRequests: tradeRequests.reverse(),
      pendingRequestsNum: pendingRequests.length,
      isLoading: false,
    })
    console.log(values)
  }, [])

  return (
    <>
      {values.isLoading ? (
        <Loading />
      ) : (
        <>
          <PageTitle title="Trade Requests" />
          {values.tradeRequests.map((request) => {
            return (
              <RequestCard
                key={request.transactionID}
                teamID={values.teamID}
                request={request}
                minusPendingRequestsCount={minusPendingRequestsCount}
              />
            )
          })}
        </>
      )}
    </>
  )
}

export default TradeRequests
