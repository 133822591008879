// Libraries
import React, { useState } from 'react'

// Material UI
import Divider from '@material-ui/core/Divider'
import SportsHockeyTwoToneIcon from '@material-ui/icons/SportsHockeyTwoTone'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Typography from '@material-ui/core/Typography'
import ListSubheader from '@material-ui/core/ListSubheader'
import { makeStyles } from '@material-ui/core/styles'

// Components
import { DraftEditor, PageTitle } from '../../components'
import { Button, Modal, TeamDropDown, TextField } from '../../components/UIkits'

// APIs

// Other
import { validateDraftManagement } from '../../service/validateInfo'
import { draftManagementForm as useForm } from '../../service/useForm'

const DraftManagement = ({ submitForm }) => {
  const classes = useStyles()

  const {
    handleChange,
    validateInput,
    handleSubmit,
    values,
    setValues,
    updateTeam,
    updateOriginalTeam,
    errors,
    modalIsOpen,
    setModalIsOpen,
  } = useForm(submitForm, validateDraftManagement)

  const draftRounds = [
    { round: 1, order: values.round1 },
    { round: 2, order: values.round2 },
    { round: 3, order: values.round3 },
  ]

  return (
    <div>
      <PageTitle title="Draft Management" />

      <form className={classes.formDiv}>
        <div className={classes.subInfoDiv}>
          {/* Year */}
          <TextField
            label="Year"
            type="number"
            name="year"
            value={values.year}
            onChange={handleChange}
            error={errors.year}
            helperText={errors.year}
          />

          {/* Season Winner */}
          <div className={classes.seasonWinner}>
            <p>Season Winner</p>
            <TeamDropDown
              className={classes.winnerDropDown}
              name="seasonWinner"
              value={values.seasonWinner}
              onChange={handleChange}
              error={errors.seasonWinner}
            />
          </div>
        </div>
        <Divider />

        {/* Round 1 */}
        <DraftEditor
          round={1}
          name="round1"
          value={values.round1}
          updateTeam={updateTeam}
          updateOriginalTeam={updateOriginalTeam}
          values={values}
          setValues={setValues}
          error={errors.round1}
        />

        {/* Round 2 */}
        <DraftEditor
          round={2}
          name="round2"
          value={values.round2}
          updateTeam={updateTeam}
          updateOriginalTeam={updateOriginalTeam}
          values={values}
          setValues={setValues}
          error={errors.round2}
        />

        {/* Round 3 */}
        <DraftEditor
          round={3}
          name="round3"
          value={values.round3}
          updateTeam={updateTeam}
          updateOriginalTeam={updateOriginalTeam}
          values={values}
          setValues={setValues}
          error={errors.round3}
        />

        <Button
          variant="contained"
          className={classes.button}
          type="submit"
          onClick={(e) => {
            // Validate Input
            validateInput(e)
          }}
        >
          Confirm
        </Button>
      </form>

      <Modal
        isOpen={modalIsOpen}
        setIsOpen={setModalIsOpen}
        className={classes.modalDiv}
      >
        <div className={classes.modalHeaderDiv}>
          <Typography variant="h3" component="h1" gutterBottom>
            <SportsHockeyTwoToneIcon />
            {`  ${values.year} Draft  `}
            <SportsHockeyTwoToneIcon />
          </Typography>

          <Typography variant="h4" component="h2">
            {`Season Winner : ${values.seasonWinner.teamName}`}
          </Typography>
        </div>

        <div className={classes.roundListDiv}>
          {draftRounds.map((draftRound) => (
            <List
              key={draftRound.round}
              subheader={
                <ListSubheader
                  component="div"
                  className={classes.listSubheader}
                >
                  {`Round ${draftRound.round}`}
                </ListSubheader>
              }
              className={classes.roundList}
            >
              {draftRound.order.map((team, index) => (
                <ListItem key={team.teamID}>
                  <ListItemText
                    primary={`${index + 1}. ${
                      team.team ? team.team.teamName : ''
                    }`}
                    secondary={`(${
                      team.originalTeam ? team.originalTeam.teamName : ''
                    })`}
                  />
                </ListItem>
              ))}
            </List>
          ))}
        </div>

        <div className={classes.buttonDiv}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            className={classes.button}
            disabled={
              values.year === undefined ||
              values.seasonWinner.teamName === undefined
            }
          >
            Submit
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              setModalIsOpen(false)
            }}
            className={classes.button}
          >
            Back
          </Button>
        </div>
      </Modal>
    </div>
  )
}

const useStyles = makeStyles({
  button: {
    margin: '0 auto',
    width: '120px',
    whiteSpace: 'nowrap',
  },
  buttonDiv: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  formDiv: {
    margin: '50px 0',
    display: 'flex',
    flexDirection: 'column',
  },
  listSubheader: {
    textAlign: 'center',
  },
  modalDiv: {
    padding: '100px',
  },
  modalHeaderDiv: {
    textAlign: 'center',
    marginBottom: '20px',
  },
  roundListDiv: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  roundList: {
    margin: '50px',
    width: '200px',
  },
  subInfoDiv: {
    margin: '50px 0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  seasonWinner: {
    marginTop: '20px',
    '& p': {
      textAlign: 'center',
    },
  },
  winnerDropDown: {
    minWidth: '200px',
  },
})

export default DraftManagement
