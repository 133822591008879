import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

// Material UI
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'

// Components
import PageTitle from '../../components/PageTitle'

import {
  positionListAPI,
  searchPlayerByIDAPI,
  editPlayerAPI,
} from '../../adapters/adminAPI'

const useStyles = makeStyles({
  formWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  input: {
    margin: '30px 5px',
  },
  hockeyDBURL: {
    width: '120px',
  },
  dropDown: {
    width: '150px',
  },
  nhlGames: {
    width: '100px',
  },
  button: {
    margin: '0 auto',
  },
})

const AdminEditPlayer = ({ submitForm }) => {
  const classes = useStyles()
  const [dbId, setdbId] = useState(),
    [position, setPosition] = useState(),
    [playerName, setPlayerName] = useState(),
    [gamesPlayed, setGamesPlayed] = useState(),
    [positionList, setPositionList] = useState([]),
    [isLoading, setIsLoading] = useState(false)

  // Extract playerID from the URL
  const history = useHistory()
  const playerID = history.location.search.replace(/[^0-9]/g, '')

  const submit = () => {
    editPlayerAPI(playerID, dbId, position, playerName, gamesPlayed).then(
      () => {
        alert('Player data has been updated.')
        history.push('/adminDashboard/playersList')
      }
    )
  }

  useEffect(() => {
    setIsLoading(true)

    const fetchData = async () => {
      // GET player info
      await searchPlayerByIDAPI(playerID).then((data) => {
        setdbId(data.profileURL)
        setPosition(data.positionID)
        setPlayerName(data.playerName)
        setGamesPlayed(data.numberOfGames)
      })

      // GET position list for the dropdown
      await positionListAPI().then((data) => {
        setPositionList(data)
      })
    }

    fetchData().then(() => {
      setIsLoading(false)
    })
  }, [])

  return (
    <>
      <PageTitle title="Edit Player" />

      {!isLoading && (
        <form className={classes.formWrapper}>
          {/* hockeyDBURL */}
          <TextField
            label="Hockey DB Player ID"
            name="hockeyDBURL"
            className={`${classes.input} ${classes.hockeyDBURL}`}
            defaultValue={dbId}
            value={dbId}
            onChange={(e) => setdbId(e.target.value)}
          />

          {/* position */}
          <FormControl
            className={`${classes.input} ${classes.dropDown}`}
            variant="outlined"
          >
            <InputLabel id="positionDropdown-label">Position</InputLabel>
            <Select
              labelId="positionDropdown-label"
              id="positionDropdown"
              label="Position"
              name="position"
              onChange={(e) => setPosition(e.target.value)}
              defaultValue={position}
              value={position}
            >
              {positionList.map((option, index) => (
                <MenuItem key={option.positionID} value={option.positionID}>
                  {option.position}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* Player Name */}
          <TextField
            label="Player Name"
            name="playerName"
            className={`${classes.input} ${classes.input}`}
            defaultValue={playerName}
            value={playerName}
            onChange={(e) => setPlayerName(e.target.value)}
          />

          {/* nhlGames */}
          <TextField
            label="NHL Games"
            name="nhlGames"
            defaultValue={gamesPlayed}
            value={gamesPlayed}
            onChange={(e) => setGamesPlayed(e.target.value)}
            className={`${classes.input} ${classes.nhlGames}`}
            type="number"
          />
          <Button variant="contained" onClick={submit}>
            Submit
          </Button>
        </form>
      )}
    </>
  )
}

export default AdminEditPlayer
