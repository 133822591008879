import { get, put } from '../xhr/index'

// PUT In-Team tatus
export const changeInTeamStatusAPI = async (playerID, inTeamStatus) => {
  return put('/players', {
    playerID: playerID,
    inTeamStatusID: inTeamStatus,
  }).catch((err) => console.log(err))
}

// GET all inactive players
export const getInactivePlayers = async () => {
  return get(`/players/inactive`).then((res) => {
    return res.data
  })
}

// GET players searching by keyword
export const searchPlayerAPI = async (limit, keyword) => {
  return get(`/players/search/${limit}/${keyword}`).then((res) => {
    return res.data
  })
}

// PUT Player
export const putPlayer = async (player) => {
  const {
    playerID,
    playerName,
    positionID,
    inTeamStatusID,
    numberOfGames,
    profileURL,
  } = player

  return put('/players', {
    playerID: playerID,
    playerName: playerName,
    positionID: positionID,
    inTeamStatusID: inTeamStatusID,
    numberOfGames: numberOfGames,
    profileURL: profileURL,
    active: true,
  }).catch((err) => console.log(err))
}

// Delete Player
export const deletePlayer = async (playerID) => {
  return put('/players/delete', {
    playerID: playerID,
  }).catch((err) => console.log(err))
}
