// Libraries
import React, { useState, useEffect } from 'react'

// Material UI
import Divider from '@material-ui/core/Divider'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import { makeStyles } from '@material-ui/core/styles'

// Redux
import { useSelector, useDispatch } from 'react-redux'
import { getPlayerRequests } from '../../reducks/playerRequests/selector'
import { fetchPlayerRequest } from '../../reducks/playerRequests/operations'

// Components
import PageTitle from '../../components/PageTitle'
import {
  Button,
  InTeamStatusesDropDown,
  PositionsDropDown,
  TeamDropDown,
  TextField,
} from '../../components/UIkits'

// useForm
import { playerRequestForm as useForm } from '../../service/useForm'

const useStyles = makeStyles({
  buttonsDiv: {
    display: 'flex',
    justifyContent: 'space-around',
    margin: '20px auto',
    width: '80%',
  },
  formWrapper: {
    margin: '50px 0',
  },
  inputsDiv: {
    display: 'flex',
    flexDirection: 'column',

    '&>div': {
      display: 'flex',
      justifyContent: 'space-around',
      margin: '10px auto',
      width: '80%',
    },
  },
  list: {
    width: '100%',
  },
  playerID: {
    width: '80px',
  },
  nhlGames: {
    width: '80px',
  },
})

const AddPlayerRequests = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const selector = useSelector((state) => state)
  const playerRequests = getPlayerRequests(selector)
  const { list } = playerRequests

  const {
    deleteRequest,
    handleChange,
    handleSubmit,
    formValues,
    setFormValues,
  } = useForm()

  useEffect(() => {
    console.log('Updated🎎')
    setFormValues(list)
  }, [list])

  return (
    <>
      <PageTitle title="Request List" />

      <List className={classes.list}>
        {formValues.map((request, index) => (
          <div>
            <ListItem key={request.playerID}>
              <ListItemText
                primary={
                  <div className={classes.inputsDiv}>
                    <div>
                      {/* Player ID */}
                      <TextField
                        className={classes.playerID}
                        label="Player ID"
                        name="profileURL"
                        onChange={(e) => handleChange(e, index)}
                        type="number"
                        value={request.profileURL}
                      />

                      {/* team */}
                      <TeamDropDown
                        label="Team"
                        onChange={(e) => handleChange(e, index)}
                        value={{
                          teamID: request.teamID,
                          teamName: request.teamName,
                        }}
                        name="team"
                        admin
                      />

                      {/* Positions */}
                      <PositionsDropDown
                        onChange={(e) => handleChange(e, index)}
                        value={request.positionID}
                        name="positionID"
                      />

                      {/* In-Team Statuses */}
                      <InTeamStatusesDropDown
                        onChange={(e) => handleChange(e, index)}
                        value={request.inTeamStatusID}
                        name="inTeamStatusID"
                      />
                    </div>
                    <div>
                      {/* Player Name */}
                      <TextField
                        label="Name"
                        name="playerName"
                        onChange={(e) => handleChange(e, index)}
                        type="string"
                        value={request.playerName}
                      />

                      {/* NHL Games */}
                      <TextField
                        className={classes.nhlGames}
                        label="NHL Games"
                        name="numberOfGames"
                        onChange={(e) => handleChange(e, index)}
                        type="number"
                        value={request.numberOfGames}
                      />
                    </div>
                  </div>
                }
                secondary={
                  <div className={classes.buttonsDiv}>
                    <Button
                      color="primary"
                      onClick={() =>
                        handleSubmit(request).then(() => {
                          dispatch(fetchPlayerRequest())
                        })
                      }
                      variant="contained"
                    >
                      Confirm
                    </Button>
                    <Button
                      color="secondary"
                      onClick={() => {
                        deleteRequest(request.playerID).then(() => {
                          dispatch(fetchPlayerRequest())
                        })
                      }}
                      variant="contained"
                    >
                      Delete
                    </Button>
                  </div>
                }
              />
            </ListItem>
            <Divider />
          </div>
        ))}
      </List>
    </>
  )
}

export default AddPlayerRequests
