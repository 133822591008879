import * as Actions from './actions'
import initialState from '../store/initialState'

export const LeaguesReducer = (state = initialState.leagues, action) => {
  switch (action.type) {
    case Actions.FETCH_LEAGUE_STATUS:
      return {
        ...state,
        ...action.payload,
      }
    case Actions.FETCH_ROSTER_LIMIT:
      return {
        ...state,
        rosterLimits: {
          ...action.payload,
        },
      }
    default:
      return state
  }
}
