import React, { useEffect } from 'react'
import { withRouter, useHistory } from 'react-router-dom'
import axios from 'axios'

import { toast } from 'react-toastify'

import getJwt from './getJwt'

import { BASE_URL } from '../config/config'

const AuthenticationComp = (props) => {
  const history = useHistory()
  const { children } = props

  useEffect(() => {
    const jwt = getJwt()

    // Check if the token is still valid
    axios
      .get(`${BASE_URL}/users/info`, {
        headers: { Authorization: `${jwt}` },
      })
      .then((res) => {
        if (res.data.isAdmin) {
          history.push('/adminDashboard')
        }
      })
      .catch(() => {
        toast('Please log in again.')
        localStorage.removeItem('jwt-token')
        history.push('/')
      })
  }, [])

  return <>{children}</>
}

export default withRouter(AuthenticationComp)
