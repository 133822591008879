export const FETCH_USER_INFO = 'FETCH_USER_INFO'
export const fetchUserInfoAction = (userState) => {
  return {
    type: 'FETCH_USER_INFO',
    payload: {
      userID: userState.userID,
      firstName: userState.firstName,
      lastName: userState.lastName,
      userName: userState.userName,
      teamID: userState.teamID,
      teamName: userState.teamName,
      email: userState.email,
      isAdmin: userState.is_admin,
    },
  }
}
