import { React } from 'react'
import Router from './Router'

import Toaster from './components/Toaster'

const App = () => {
  return (
    <div id="root" className="App">
      <main>
        <Router />
      </main>

      <Toaster />
    </div>
  )
}

export default App
