import { createSelector } from 'reselect'

const leaguesSelector = (state) => state.leagues

export const isSigningSuspended = createSelector(
  [leaguesSelector],
  (state) => state.isSigningSuspended
)

export const isTradingSuspended = createSelector(
  [leaguesSelector],
  (state) => state.isTradingSuspended
)

export const rosterLimitsValues = createSelector(
  [leaguesSelector],
  (state) => state.rosterLimits
)
