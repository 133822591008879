// Libraries
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

// Material UI
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import Link from '@material-ui/core/Link'
import Typography from '@material-ui/core/Typography'
import Launch from '@material-ui/icons/Launch'
import AddCircleOutline from '@material-ui/icons/AddCircleOutline'
import IconButton from '@material-ui/core/IconButton'

// Redux
import { useDispatch, useSelector } from 'react-redux'
import { getUserInfo } from '../reducks/users/selector'
import { fetchTeamData } from '../reducks/teams/operations'

// Custom Components
import { Button, Modal, TeamDropDown } from './UIkits'

// APIs
import {
  postTransactionAPI,
  putPlayerTeamLinkAPI,
  stillInFaAPI,
} from '../adapters/transactionsAPI'

// Others
import { ihdbBaseURL } from '../config/config'

const SignPlayerTable = (props) => {
  const classes = useStyles()
  const history = useHistory()
  const dispatch = useDispatch()
  const { searchResults } = props

  // Redux
  const selector = useSelector((state) => state)
  const userInfo = getUserInfo(selector)
  const { teamID, isAdmin } = userInfo

  const [values, setValues] = useState({
    modalIsOpen: false,
    playerName: '',
    playerID: undefined,
    teamPlayerPairs: [],
  })

  const resetValues = () => {
    setValues({
      modalIsOpen: false,
      playerName: '',
      playerID: undefined,
      teamPlayerPairs: [],
    })
  }

  const signPlayer = async (playerID) => {
    // Check if the player is still in FA
    // (Meaning the player has not been taken by other team yet)
    const isInFA = await stillInFaAPI(playerID)

    if (isInFA) {
      const transactionDetail = {
        team2ID: 2,
        transactionType: 'Sign',
        requestStatusID: 6,
        players: [{ teamID: 2, playerID: playerID }],
      }
      // POST a transaction record
      postTransactionAPI(transactionDetail)

      // PUT player team link
      putPlayerTeamLinkAPI(teamID, playerID)
        .then(() => {
          resetValues()
        })
        .then(() => {
          dispatch(fetchTeamData())
          // Sent to Your Team Page
          history.push('/dashboard')
        })
    } else {
      alert('The player has been taken by other team.')
      history.push('/dashboard')
    }
  }

  // Submit Transfer Request(Admin use)
  const submitTransferRequest = async (playerID, newTeamID) => {
    // If the player retires, POST a transaction record
    // if (newTeamID === 30) {
    //   const transactionDetail = {
    //     team2ID: 30,
    //     transactionType: 'Retire',
    //     requestStatusID: 6,
    //     players: [{ teamID: 2, playerID: playerID }],
    //   }

    //   // POST a transaction record
    //   postTransactionAPI(transactionDetail)
    // }

    // PUT player team link
    await putPlayerTeamLinkAPI(newTeamID, playerID)
  }

  return (
    <div className={classes.wrapper}>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="Search result">
          <TableHead>
            <TableRow>
              <TableCell>Player</TableCell>
              <TableCell>Team</TableCell>
              {/* If the user is admin, give an ability to transfer players from a team to a team. */}
              {isAdmin ? (
                <TableCell>Transfer</TableCell>
              ) : (
                <TableCell>Sign</TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {searchResults.map((player) => (
              <TableRow key={player.playerID}>
                {/* Player Name */}
                <TableCell component="th" scope="row">
                  <Link
                    href={`${ihdbBaseURL}${player.profileURL}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <IconButton>
                      <Launch className={classes.launchIcon} />
                    </IconButton>
                    {player.playerName}({player.position})
                  </Link>
                </TableCell>
                {/* Team */}
                <TableCell>{player.teamName}</TableCell>
                {isAdmin ? (
                  <>
                    {/* Team Dropdown */}
                    <TableCell>
                      <TeamDropDown
                        admin
                        className={classes.formControl}
                        onChange={(event) => {
                          submitTransferRequest(
                            player.playerID,
                            JSON.parse(event.target.value).teamID
                          )
                        }}
                      />
                    </TableCell>

                    {/* Edit Button */}
                    <TableCell>
                      <Button
                        variant="contained"
                        onClick={() =>
                          history.push(
                            `/adminDashboard/editPlayer/?playerId=${player.playerID}`
                          )
                        }
                      >
                        Edit
                      </Button>
                    </TableCell>
                  </>
                ) : (
                  <TableCell>
                    {player.teamName === 'Free Agent' && (
                      <IconButton
                        onClick={() =>
                          setValues({
                            ...values,
                            modalIsOpen: true,
                            playerName: player.playerName,
                            playerID: player.playerID,
                          })
                        }
                      >
                        <AddCircleOutline />
                      </IconButton>
                    )}
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Modal when the add button gets clickd */}
      <Modal isOpen={values.modalIsOpen}>
        <Typography>
          You are about to sign `{values.playerName}` to your team.
        </Typography>

        <div>
          <Button
            className={classes.button}
            variant="contained"
            type="button"
            onClick={() => {
              signPlayer(values.playerID)
            }}
          >
            OK
          </Button>
          <Button
            className={classes.button}
            variant="contained"
            type="button"
            onClick={() => resetValues()}
          >
            Cancel
          </Button>
        </div>
      </Modal>
    </div>
  )
}

const useStyles = makeStyles({
  wrapper: {
    margin: '20px 0',
  },
  title: {
    margin: '20px 0',
    textAlign: 'center',
  },
  table: {
    minWidth: 650,
  },
  launchIcon: {
    marginRight: '10px',
  },
  button: {
    margin: '2rem',
  },
  formControl: {
    width: '100%',
  },
  teamPlayerPairsDiv: {
    position: 'fixed',
    zIndex: 2,
    right: '20px',
    bottom: '20px',
    backgroundColor: '#cce3fe',
    padding: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'column',
    overflow: 'scroll',
    maxHeight: '90vh',
  },
  align: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
})

export default SignPlayerTable
