import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useHistory } from 'react-router-dom'

import { toast } from 'react-toastify'

import getJwt from '../authentication/getJwt'
import { BASE_URL } from '../config/config'

import {setNewPasswordAPI, signupAPI} from '../adapters/usersAPI'
import { postTransactionAPI } from '../adapters/adminAPI'
import { postDraft, postDraftPick } from '../adapters/draftAPI'
import { putPlayerTeamLinkAPI } from '../adapters/transactionsAPI'
import { deletePlayer, putPlayer } from '../adapters/playersAPI'

// Sign up form
export const signupForm = (callback, validate) => {
  const history = useHistory()

  const [values, setValues] = useState({
    firstName: '',
    lastName: '',
    userName: '',
    email: '',
    password: '',
    password2: '',
    teamID: '',
  })

  const [errors, setErrors] = useState({})
  const [isSubmitting, setIsSubmitting] = useState(false)

  const handleChange = (e) => {
    const { name, value } = e.target
    setValues({
      ...values,
      [name]: value,
    })
  }

  const updateTeam = (e) => {
    const { name, value } = e.target
    const team = JSON.parse(value)

    setValues({
      ...values,
      [name]: team,
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    setErrors(validate(values))
    setIsSubmitting(true)
  }

  useEffect(async () => {
    console.log(values.teamID.teamID)
    if (Object.keys(errors).length === 0 && isSubmitting) {
      // POST a new user
      await signupAPI(
        values.firstName,
        values.lastName,
        values.userName,
        values.email,
        values.teamID.teamID,
        values.password
      ).then(() => {
        if (getJwt()) {
          history.push('/dashboard')
        }
      })
    }
  }, [errors])

  return { handleChange, handleSubmit, values, setValues, errors, updateTeam }
}

// Add PLayer Form
export const addPlayerForm = (callback, validate) => {
  const [formValues, setFormValues] = useState({
    hockeyDBURL: '',
    playerName: '',
    position: '',
    team: '',
    inTeamStatusID: '',
    nhlGames: 0,
    is_active: true,
    modalIsOpen: false,
  })

  const [errors, setErrors] = useState({})
  const [isSubmitting, setIsSubmitting] = useState(false)

  const handleChange = (e) => {
    const { name, value } = e.target

    setFormValues({
      ...formValues,
      [name]: value,
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    setErrors(validate(formValues))
    setIsSubmitting(true)
  }

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      console.log(formValues.is_active)
      axios
        .post(`${BASE_URL}/players`, {
          playerName: formValues.playerName,
          positionID: formValues.position,
          inTeamStatusID: formValues.inTeamStatusID,
          numberOfGames: formValues.nhlGames,
          profileURL: formValues.hockeyDBURL,
          teamID: formValues.team,
          active: formValues.is_active,
        })
        .then((data) => {
          if (
            formValues.team !== 1 &&
            formValues.team !== 2 &&
            formValues.team !== 30 &&
            formValues.is_active
          ) {
            postTransactionAPI(data.data.playerID, formValues.team).catch(
              (err) => {
                console.log(err)
              }
            )
          }
        })
        .then(() => {
          setFormValues({
            ...formValues,
            hockeyDBURL: '',
            playerName: '',
            position: '',
            team: '',
            inTeamStatusID: '',
            nhlGames: 0,
            modalIsOpen: true,
          })
        })
        .catch((err) => {
          toast.error(err.response.data.message)
        })
    }
  }, [errors])

  return {
    handleChange,
    handleSubmit,
    formValues,
    setFormValues,
    errors,
  }
}

// Draft Management
export const draftManagementForm = (callback, validate) => {
  const history = useHistory()
  const [values, setValues] = useState({
    year: 2021,
    seasonWinner: {},
    round1: [''],
    round2: [''],
    round3: [''],
  })

  const [modalIsOpen, setModalIsOpen] = useState(false)

  const [errors, setErrors] = useState({})

  const handleChange = (e) => {
    const { name, value } = e.target

    if (name === 'seasonWinner') {
      setValues({
        ...values,
        [name]: JSON.parse(value),
      })
    } else {
      setValues({
        ...values,
        [name]: value,
      })
    }
  }

  const updateOriginalTeam = (index, teamString, name) => {
    const team = JSON.parse(teamString)
    const newDraftOrder = values[name].map((row, i) => {
      if (i === index)
        return {
          ...row,
          originalTeam: { teamID: team.teamID, teamName: team.teamName },
        }
      return row
    })

    setValues({
      ...values,
      [name]: newDraftOrder,
    })
  }

  const updateTeam = (index, teamString, name) => {
    const team = JSON.parse(teamString)
    const newDraftOrder = values[name].map((row, i) => {
      if (i === index)
        return {
          ...row,
          team: { teamID: team.teamID, teamName: team.teamName },
        }
      return row
    })

    setValues({
      ...values,
      [name]: newDraftOrder,
    })
  }

  const validateInput = (e) => {
    e.preventDefault()
    setErrors(validate(values))
    setModalIsOpen(true)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    await postDraft(values.year, values.seasonWinner.teamID)
      .then(async (res) => {
        const draftID = res.id

        await postDraftPick(
          draftID,
          values.round1,
          values.round2,
          values.round3
        )
      })
      .then((res) => {
        toast('New Season Draft has been created.')
        history.push('/adminDashboard')
      })
      .catch((err) => {
        toast.error(err)
      })
  }

  return {
    handleChange,
    validateInput,
    handleSubmit,
    values,
    setValues,
    updateOriginalTeam,
    errors,
    updateTeam,
    modalIsOpen,
    setModalIsOpen,
  }
}

// Approve/Edit Player Request
export const playerRequestForm = (callback, validate) => {
  const [formValues, setFormValues] = useState([])
  const [errors, setErrors] = useState({})
  const [isSubmitting, setIsSubmitting] = useState(false)

  const handleChange = (e, index) => {
    const { name, value } = e.target

    const formValuesCopy = formValues.slice()

    if (name === 'team') {
      const team = JSON.parse(value)

      formValuesCopy[index] = {
        ...formValuesCopy[index],
        teamID: team.teamID,
        teamName: team.teamName,
      }
    } else {
      formValuesCopy[index] = {
        ...formValuesCopy[index],
        [name]: value,
      }
    }

    setFormValues(formValuesCopy)
  }

  const handleSubmit = async (player) => {
    await putPlayer(player)
    await putPlayerTeamLinkAPI(player.teamID, player.playerID)

    if (player.teamID !== 1 && player.teamID !== 2 && player.teamID !== 30) {
      await postTransactionAPI(player.playerID, player.teamID).catch((err) => {
        console.log(err)
      })
    }
  }

  const deleteRequest = async (playerID) => {
    await deletePlayer(playerID)
  }

  return {
    deleteRequest,
    handleChange,
    handleSubmit,
    formValues,
    setFormValues,
    errors,
  }
}

// Set New Password (Reset Password)
export const setNewPasswordForm = (callback, validate, email, token) => {
    const history = useHistory()
    const [values, setValues] = useState({
        password: '',
        confirmPassword: '',
    })

    const [errors, setErrors] = useState({})
    const [isSubmitting, setIsSubmitting] = useState(false)

    const handleChange = (e) => {
        const { name, value } = e.target
        setValues({
            ...values,
            [name]: value,
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        setErrors(validate(values))
        setIsSubmitting(true)
    }

    useEffect(async () => {
        if (Object.keys(errors).length === 0 && isSubmitting) {
            console.log(email, token, values.password)
            await setNewPasswordAPI(email, values.password, token)
                .then(()=>{
                    history.push('/login')
                })
                .catch((err)=>{
                    console.log(err)
                })
        }
    }, [errors])

    return { handleChange, handleSubmit, values, setValues, errors }
}