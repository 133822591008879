import * as Actions from './actions'
import initialState from '../store/initialState'

export const TeamsReducer = (state = initialState.teams, action) => {
  switch (action.type) {
    case Actions.FETCH_ROSTER:
      return {
        ...state,
        ...action.payload,
      }
    default:
      return state
  }
}
