// Libraries
import React, { useState, useEffect } from 'react'
import { Switch, Route, Link, useHistory } from 'react-router-dom'

// Material UI
import Container from '@material-ui/core/Container'
import Badge from '@material-ui/core/Badge'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'

// Redux
import { useSelector, useDispatch } from 'react-redux'
import { getUserInfo } from '../../reducks/users/selector'
import { getPlayerRequests } from '../../reducks/playerRequests/selector'
import { fetchUserInfo } from '../../reducks/users/operations'
import { fetchTeamData } from '../../reducks/teams/operations'
import { fetchPlayerRequest } from '../../reducks/playerRequests/operations'
import { fetchHelpers } from '../../reducks/helpers/operations'
import { fetchRosterLimits } from '../../reducks/leagues/operations'

// Components
import { Drafts, TransactionHistory, OtherTeam } from '..'
import { TeamDropDown } from '../../components/UIkits'

import {
  AdminAddPlayers,
  AdminEditPlayer,
  AdminLeagueManagement,
  AdminPlayersList,
  AdminTradeRequests,
  AddPlayerRequests,
  DraftManagement,
} from '.'

// APIs
import { notificationAPI, teamListAPI } from '../../adapters/adminAPI'

const AdminDashboard = () => {
  const classes = useStyles()
  const history = useHistory()
  const dispatch = useDispatch()
  const selector = useSelector((state) => state)
  const userInfo = getUserInfo(selector)
  const { isAdmin } = userInfo
  const playerRequests = getPlayerRequests(selector)
  const { count } = playerRequests

  const [values, setValues] = useState({
    teamList: [],
    teamName: '',
    pendingRequests: 0,
  })

  useEffect(async () => {
    await dispatch(fetchUserInfo())
    await dispatch(fetchTeamData())
    await dispatch(fetchPlayerRequest())
    await dispatch(fetchHelpers())
    await dispatch(fetchRosterLimits())

    // If the user is not admin, sent to Your Team Page
    if (!isAdmin) {
      history.push('/dashboard')
    }

    // GET team list for the dropdown
    const teamList = await teamListAPI()

    // GET a number of pending requests for the notification
    const pendingRequestsList = await notificationAPI()

    setValues({
      ...values,
      teamList: teamList,
      pendingRequests: pendingRequestsList.length,
    })
  }, [])

  // Handler for the team dropdown
  const handleChange = (event) => {
    setValues({ ...values, teamName: event.target.value })
  }

  const logout = async () => {
    await localStorage.removeItem('jwt-token')
    window.location.reload()
  }

  return (
    <Container className={classes.outerWrapper}>
      <Container className={`${classes.wrapper} ${classes.navBar}`}>
        <Link to="/adminDashboard" className={classes.link}>
          Transaction History
        </Link>

        <Badge badgeContent={values.pendingRequests} color="primary">
          <Link to="/adminDashboard/tradeRequests" className={classes.link}>
            Pending Trades
          </Link>
        </Badge>

        <Badge badgeContent={count} color="primary">
          <Link to="/adminDashboard/addPlayers" className={classes.link}>
            Add Players
          </Link>
        </Badge>

        {/* Team Dropdown */}
        <TeamDropDown className={classes.formControl} link admin />

        <Link to="/adminDashboard/playersList" className={classes.link}>
          Player List
        </Link>

        <Link to="/adminDashboard/league" className={classes.link}>
          League Management
        </Link>

        <Link to="/adminDashboard/draft" className={classes.link}>
          Drafts
        </Link>

        <Button onClick={logout}>Log out</Button>
      </Container>

      <Container className={classes.wrapper}>
        <Switch>
          <Route exact path="/adminDashboard" component={TransactionHistory} />

          <Route exact path="/adminDashboard/tradeRequests">
            <AdminTradeRequests
              topValues={values}
              pendingRequests={values.pendingRequests}
              setPendingRequests={setValues}
            />
          </Route>

          <Route
            exact
            path="/adminDashboard/addPlayers"
            component={AdminAddPlayers}
          />

          <Route
            exact
            path="/adminDashboard/addPlayers/requests"
            component={AddPlayerRequests}
          />

          <Route path="/adminDashboard/team" component={OtherTeam} />

          <Route
            path="/adminDashboard/playersList"
            component={AdminPlayersList}
          />

          <Route
            path="/adminDashboard/league"
            component={AdminLeagueManagement}
          />

          <Route
            path="/adminDashboard/editPlayer"
            component={AdminEditPlayer}
          />

          <Route
            path="/adminDashboard/draft/edit"
            component={DraftManagement}
          />

          <Route path="/adminDashboard/draft" component={Drafts} />
        </Switch>
      </Container>
    </Container>
  )
}

const useStyles = makeStyles({
  outerWrapper: {
    display: 'flex',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px solid black',
    margin: '5px',
    padding: '20px',
  },
  navBar: {
    width: '400px',
    height: '500px',
    position: 'sticky',
    top: '0',
  },
  link: {
    margin: '5px 0 20px',
  },
  formControl: {
    margin: 10,
    minWidth: 120,
  },
  numberIcon: {
    display: 'inline-block',
    margin: '0 10px',
    padding: '5px',
    height: '30px',
    minWidth: '30px',
    borderRadius: '50%',
    color: 'white',
    background: 'red',
    textAlign: 'center',
  },
})

export default AdminDashboard
