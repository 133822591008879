import React from 'react'

import CachedIcon from '@material-ui/icons/Cached'

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  cachedIcon: {},
})

const Loading = () => {
  const classes = useStyles()

  return (
    <>
      <CachedIcon className={classes.cachedIcon} />
    </>
  )
}

export default Loading
