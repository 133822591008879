// Libraries
import React, { useState, useEffect } from 'react'
import Modal from 'react-modal'
import { Link } from 'react-router-dom'

// Material UI
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import FormHelperText from '@material-ui/core/FormHelperText'
import { makeStyles } from '@material-ui/core/styles'

// Redux
import { useSelector } from 'react-redux'
import { getUserInfo } from '../../reducks/users/selector'

// Components
import PageTitle from '../../components/PageTitle'

// useForm
import { validateAddPlayer } from '../../service/validateInfo'
import { addPlayerForm as useForm } from '../../service/useForm'

// APIs
import {
  teamListAPI,
  positionListAPI,
  inTeamStatusListAPI,
} from '../../adapters/adminAPI'

Modal.setAppElement('#root')

const useStyles = makeStyles({
  formWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  input: {
    margin: '30px 5px',
  },
  link: {
    margin: '30px 0',
  },
  hockeyDBURL: {
    width: '120px',
  },
  dropDown: {
    width: '150px',
  },
  nhlGames: {
    width: '100px',
  },
  button: {
    margin: '0 auto',
  },
})

const modalStyle = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    backgroundColor: 'rgba(0,0,0,0.85)',
  },
  content: {
    position: 'absolute',
    top: '20rem',
    left: '20rem',
    right: '20rem',
    bottom: '20rem',
    backgroundColor: 'white',
    padding: '1.5rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
}

const AdminAddPlayer = ({ submitForm }) => {
  const classes = useStyles()

  // Redux
  const selector = useSelector((state) => state)
  const userInfo = getUserInfo(selector)
  const { isAdmin } = userInfo

  const [values, setValues] = useState({
    teamList: [{ teamID: '', teamName: '' }],
    positionList: [{ positionID: '', position: '' }],
    inTeamStatusList: [{ inTeamStatusID: '', inTeamStatus: '' }],
  })

  const {
    handleChange,
    handleSubmit,
    formValues,
    setFormValues,
    errors,
  } = useForm(submitForm, validateAddPlayer)

  useEffect(async () => {
    // GET team list for the dropdown
    const teams = await teamListAPI()

    // GET position list for the dropdown
    const positions = await positionListAPI()

    // GET In-Team Status list for the dropdown
    const inTeamStatuses = await inTeamStatusListAPI()

    setValues({
      ...values,
      teamList: teams,
      inTeamStatusList: inTeamStatuses,
      positionList: positions,
    })

    // If the user is not admin, the request will be sent to admin
    if (!isAdmin) {
      handleChange({ target: { name: 'is_active', value: false } })
    }
  }, [])

  return (
    <>
      <>
        <PageTitle title="Add Player" />
        {isAdmin && (
          <Link
            to="/adminDashboard/addPlayers/requests"
            className={classes.link}
          >
            Requests from GMs
          </Link>
        )}
        <form className={classes.formWrapper}>
          <div>
            {/* hockeyDBURL */}
            <TextField
              label="Hockey DB Player ID"
              name="hockeyDBURL"
              className={`${classes.input} ${classes.hockeyDBURL}`}
              value={formValues.hockeyDBURL}
              onChange={handleChange}
              error={errors.hockeyDBURL}
              helpertext={errors.hockeyDBURL}
            />

            {/* position */}
            <FormControl
              className={`${classes.input} ${classes.dropDown}`}
              variant="outlined"
              error={errors.position}
            >
              <InputLabel id="positionDropdown-label">Position</InputLabel>
              <Select
                labelId="positionDropdown-label"
                id="positionDropdown"
                label="Position"
                name="position"
                onChange={handleChange}
                value={formValues.position}
                error={errors.position}
                helpertext={errors.position}
              >
                {values.positionList.map((option) => (
                  <MenuItem key={option.positionID} value={option.positionID}>
                    {option.position}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>{errors.position}</FormHelperText>
            </FormControl>

            {/* team */}
            <FormControl
              className={`${classes.input} ${classes.dropDown}`}
              variant="outlined"
              error={errors.team}
            >
              <InputLabel id="teamDropdown-label">Team</InputLabel>
              <Select
                label="Position"
                name="team"
                value={formValues.team}
                onChange={handleChange}
                error={errors.team}
                helpertext={errors.team}
                labelId="teamDropdown-label"
                id="teamDropdown"
              >
                {values.teamList.map((option) => (
                  <MenuItem key={option.teamID} value={option.teamID}>
                    {option.teamName}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>{errors.team}</FormHelperText>
            </FormControl>

            {/* inTeamStatus */}
            <FormControl
              className={`${classes.input} ${classes.dropDown}`}
              variant="outlined"
              error={errors.inTeamStatusID}
            >
              <InputLabel id="positionDropdown-label">
                In-Team Status
              </InputLabel>
              <Select
                name="inTeamStatusID"
                label="In-Team Status"
                labelId="positionDropdown-label"
                id="positionDropdown"
                onChange={handleChange}
                value={formValues.inTeamStatusID}
                error={errors.inTeamStatusID}
                helpertext={errors.inTeamStatusID}
              >
                {values.inTeamStatusList.map((option) => (
                  <MenuItem
                    key={option.inTeamStatusID}
                    value={option.inTeamStatusID}
                  >
                    {option.inTeamStatus}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>{errors.inTeamStatusID}</FormHelperText>
            </FormControl>
          </div>
          <div>
            {/* playerName */}
            <TextField
              label="Player Name"
              name="playerName"
              value={formValues.playerName}
              onChange={handleChange}
              className={classes.input}
              error={errors.playerName}
              helpertext={errors.playerName}
            />

            {/* nhlGames */}
            <TextField
              label="NHL Games"
              name="nhlGames"
              value={formValues.nhlGames}
              onChange={handleChange}
              className={`${classes.input} ${classes.nhlGames}`}
              error={errors.nhlGames}
              helpertext={errors.nhlGames}
              type="number"
            />
          </div>

          {isAdmin ? (
            <Button
              className={classes.button}
              variant="contained"
              color="primary"
              type="button"
              onClick={handleSubmit}
            >
              Add Player
            </Button>
          ) : (
            <Button
              className={classes.button}
              variant="contained"
              color="primary"
              type="button"
              onClick={handleSubmit}
            >
              Submit Request
            </Button>
          )}
        </form>
      </>

      <Modal isOpen={formValues.modalIsOpen} style={modalStyle}>
        {isAdmin ? (
          <Typography>Player has been created.</Typography>
        ) : (
          <Typography>Your request has been submited.</Typography>
        )}

        <div>
          <Button
            variant="contained"
            type="button"
            className={classes.button}
            onClick={() =>
              setFormValues({
                ...formValues,
                modalIsOpen: false,
              })
            }
          >
            OK
          </Button>
        </div>
      </Modal>
    </>
  )
}

export default AdminAddPlayer
