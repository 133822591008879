import { get } from '../xhr/index'

// GET a list of positions
export const getPositions = async () => {
  return get('/helpers/positions').then((res) => {
    return res.data
  })
}

// GET a list of in-team statuses
export const getInTeamStatuses = async () => {
  return get('/helpers/inTeamStatuses').then((res) => {
    return res.data
  })
}

// GET a list of teams
export const teamListAPI = async (admin) => {
  return get('/helpers/teams').then((res) => {
    // Remove Reserve and Free Agent
    return admin ? res.data : res.data.filter((n) => n.teamID > 2)
  })
}

// GET a list of draft year
export const draftYear = async () => {
  return get('/helpers/draftYears').then((res) => {
    return res.data
  })
}
