import { toast } from 'react-toastify'
import { changeInTeamStatusAPI } from '../../adapters/playersAPI'
import {
  postTransactionAPI,
  putPlayerTeamLinkAPI,
} from '../../adapters/transactionsAPI'

import { fetchTeamData } from '../teams/operations'

export const changeInTeamStatus = (
  playerID,
  inTeamStatusID,
  numberOfGames,
  position
) => {
  return (dispatch) => {
    // 1. Check if the player is too mature to be on Farms.
    const gamesLimitSkater = 125
    const gamesLimitGoalie = 80

    if (
      (inTeamStatusID === 3 &&
        numberOfGames >= gamesLimitSkater &&
        (position === 'Forward' || position === 'Defense')) ||
      (inTeamStatusID === 3 &&
        numberOfGames >= gamesLimitGoalie &&
        position === 'Goalie')
    ) {
      return toast.error(
        'The player cannot be placed on a farm team. Skaters with more than 125, and goalies with more than 80 regular season NHL games of experience are considered pro players.'
      )
    }

    // 2. Update In-Team status of the player
    return changeInTeamStatusAPI(playerID, inTeamStatusID).then(() => {
      // 3. Fetch up-to-date roster
      dispatch(fetchTeamData())
    })
  }
}

export const dropPlayer = (playerID, teamID) => {
  return (dispatch) => {
    const transactionDetail = {
      team2ID: 2,
      transactionType: 'Release',
      requestStatusID: 6,
      players: [{ teamID: teamID, playerID: playerID }],
    }

    // 1. Post a transaction record
    return postTransactionAPI(transactionDetail)
      .then(async () => {
        // 2. Change player team link
        await putPlayerTeamLinkAPI(2, playerID)
      })
      .then(() => {
        // 3. Fetch up-to-date roster
        dispatch(fetchTeamData())
      })
  }
}
