import { toast } from 'react-toastify'
import { get, post } from '../xhr/index'

// GET user Info
export const userInfoAPI = async () => {
  return get('/users/info')
}

export const signupAPI = async (
  firstName,
  lastName,
  userName,
  email,
  teamID,
  password
) => {
  await post('/users/signup', {
    firstName: firstName,
    lastName: lastName,
    userName: userName,
    email: email,
    teamID: teamID,
    password: password,
  })
    .then((res) => {
      localStorage.setItem('jwt-token', res.data.token)
    })
    .catch((err) => {
      toast.error(err.response.data)
    })
}

export const signinAPI = async (userName, password) => {
  return post('/users/login', {
    userName: userName,
    password: password,
  })
    .then((res) => {
      // Store JWT in local storage
      localStorage.setItem('jwt-token', res.data.token)
    })
    .then(() => {
      return userInfoAPI()
    })
    .catch(() => {
      localStorage.removeItem('jwt-token')
      toast.error('User Name or Password is incorrect.')
    })
}

export const signoutAPI = () => {
  localStorage.removeItem('jwt-token')
  window.location.reload()
}

export const resetPasswordAPI = async (email) => {
    await post('/users/reset_password', { email: email })
        .then((res) => {
            console.log(res)
        })
        .catch((err) => {
            console.log(err)
            toast.error('Something went wrong. Please try again.')
        })
}

export const setNewPasswordAPI = async (email, password, token) => {
    await post('/users/set_new_password', { email: email, password: password, token: token })
        .then((res) => {
            console.log(res)
        })
        .catch((err) => {
            console.log(err)
            toast.error('Something went wrong. Please try again.')
        })
}
