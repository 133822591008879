import React from 'react'

import TextField from '@material-ui/core/TextField'

const CustomTextField = (props) => {
  const {
    className,
    error,
    helperText,
    label,
    name,
    onChange,
    type,
    value,
  } = props

  return (
    <TextField
      className={className}
      error={error}
      onChange={onChange}
      type={type === 'number' ? 'number' : 'string'}
      helperText={helperText}
      label={label}
      name={name}
      value={value}
    />
  )
}

export default CustomTextField
