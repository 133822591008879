export function validateSignup(values) {
  const errors = {}

  // Sign Up
  if (!values.firstName.trim()) {
    errors.firstName = 'First name required'
  }

  if (!values.lastName.trim()) {
    errors.lastName = 'Last name required'
  }

  if (!values.teamID) {
    errors.teamID = 'Pick a team'
  }

  if (!values.userName.trim()) {
    errors.userName = 'Username required'
  }

  if (!values.email) {
    errors.email = 'Email required'
  } else if (!/\S+@\S+\.\S+/.test(values.email)) {
    errors.email = 'Email address is invalid'
  }
  if (!values.password) {
    errors.password = 'Password is required'
  } else if (values.password.length < 6) {
    errors.password = 'Password needs to be 6 characters or more'
  }

  if (!values.password2) {
    errors.password2 = 'Password is required'
  } else if (values.password2 !== values.password) {
    errors.password2 = 'Passwords do not match'
  }

  // Trade Players
  if (!values.password2) {
    errors.tradePlayers = 'Pick at least one player or draft ticket'
  }
  return errors
}

export function validateAddPlayer(values) {
  const errors = {}

  if (!values.team) {
    errors.team = 'Team required'
  }

  if (!values.position) {
    errors.position = 'Position required'
  }

  if (!values.inTeamStatusID) {
    errors.inTeamStatusID = 'In-Team Status required'
  }

  if (!values.playerName.trim()) {
    errors.playerName = 'Player name required'
  }

  if (values.nhlGames < 0) {
    errors.nhlGames = 'NHL Games cannot be negative'
  }

  return errors
}

export function validateEditPlayer(values) {
  const errors = {}

  if (!values.position) {
    errors.position = 'Position required'
  }

  if (!values.playerName.trim()) {
    errors.playerName = 'Player name required'
  }

  if (values.nhlGames < 0) {
    errors.nhlGames = 'NHL Games cannot be negative'
  }

  return errors
}

export function validateDraftManagement(values) {
  const errors = {}

  if (!values.year) {
    errors.year = 'Year required'
  }

  if (values.year < 2021) {
    errors.year = 'Invalid Value'
  }

  if (values.seasonWinner === '') {
    errors.seasonWinner = 'Season winner required'
  }

  if (values.round1.length === 1 && values.round1[0] === '') {
    errors.round1 = 'Select at least one team'
  }

  if (values.round2.length === 1 && values.round2[0] === '') {
    errors.round2 = 'Select at least one team'
  }

  if (values.round3.length === 1 && values.round3[0] === '') {
    errors.round3 = 'Select at least one team'
  }

  return errors
}

export const validateSetNewPassword = (values) => {
    const errors = {}

    if (!values.password) {
        errors.password = 'Password is required'
    } else if (values.password.length < 6) {
        errors.password = 'Password needs to be 6 characters or more'
    }

    if (!values.confirmPassword) {
        errors.confirmPassword = 'Password is required'
    } else if (values.confirmPassword !== values.password) {
        errors.confirmPassword = 'Passwords do not match'
    }

    return errors
}