export const FETCH_ROSTER = 'FETCH_ROSTER'
export const fetchRosterAction = (userState) => {
  return {
    type: 'FETCH_ROSTER',
    payload: {
      teamRoster: userState.all,
      forwards: userState.forwards,
      defenses: userState.defenses,
      goalies: userState.goalies,
      reserves: userState.reserves,
      farms: userState.farms,
      drafts: userState.drafts,
    },
  }
}
