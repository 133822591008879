// Libraries
import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { toast } from 'react-toastify'

// Material UI
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'

// APIs
import { updateTransactionsAPI } from '../adapters/tradeRequestsAPI'

const useStyles = makeStyles({
  cardWrapper: {
    width: '100%',
    padding: '30px',
    margin: '10px',
    border: '1px solid black',
  },
  pendingMessage: {
    textAlign: 'center',
    color: 'green',
    margin: '30px 0',
  },
})

const RequestCard = (props) => {
  const classes = useStyles()

  const { request, teamID, minusPendingRequestsCount } = props

  const [values, setValues] = useState({
    request: '',
    response: '',
    requestDate: '',
  })

  useEffect(() => {
    // Format trade request
    let requestStatement = ''
    if (request.team1ID === teamID) {
      requestStatement = `You have offered ${request.team1Player} for ${request.team2}'s ${request.team2Player}`
    } else if (request.team2ID === teamID) {
      requestStatement = `${request.team2} have requested ${request.team1Player} for ${request.team2Player}`
    }

    // Format response
    let responseStatement = ''
    switch (request.requestStatusID) {
      case 1: // Pending User
        if (request.team2ID === teamID) {
          responseStatement = null
        } else {
          responseStatement = `Trade Pending GM's Approval`
        }

        break
      case 2: // Pending Admin
        responseStatement = `Trade Pending Admin's Approval`
        break
      case 3: // Declined by User
        responseStatement = `Trade Has Been Declined By GM`
        break
      case 4: // Declined by Admin
        responseStatement = `Trade Has Been Declined By Admin`
        break
      case 5: // Canceled
        responseStatement = `Trade Has Been Canceled By System`
        break
      case 6: // Canceled
        responseStatement = `Trade Has Been Completed`
        break
      default:
        break
    }

    // Format date
    const formattedDate = moment(request.createdAt).format('MM/DD/YYYY')

    setValues({
      ...values,
      request: requestStatement,
      response: responseStatement,
      requestDate: formattedDate,
    })
  }, [])

  const submitResponse = (requestStatus) => {
    // PUT transaction status
    updateTransactionsAPI(request.transactionID, requestStatus)
      .then(() => {
        minusPendingRequestsCount()
      })
      .then(() => {
        if (requestStatus === 'Pending Admin') {
          setValues({
            ...values,
            response: `Trade Pending Admin's Approval`,
          })
        } else if (requestStatus === 'Declined by User') {
          setValues({
            ...values,
            response: `Trade Has Been Declined By GM`,
          })
        }
      })
      .catch((err) => {
        toast.error(err.message)
      })
  }

  return (
    <div className={classes.cardWrapper}>
      <Typography>
        {values.request} ({values.requestDate})
      </Typography>
      <Typography className={classes.pendingMessage}>
        {values.response ? (
          values.response
        ) : (
          <>
            <Button
              variant="contained"
              color="primary"
              onClick={() => submitResponse('Pending Admin')}
            >
              Accept
            </Button>{' '}
            <Button
              variant="contained"
              onClick={() => submitResponse('Declined by User')}
            >
              Refuse
            </Button>
          </>
        )}
      </Typography>
    </div>
  )
}

export default RequestCard
