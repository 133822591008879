import React from 'react'

import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'

const DropDown = (props) => {
  const { className, label, value, onChange, options } = props

  return (
    <FormControl variant="outlined" className={className}>
      <InputLabel id="label">{label}</InputLabel>
      <Select
        labelId="label"
        value={value}
        onChange={(e) => onChange(e.target.value)}
        label={label}
      >
        {options.map((option) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default DropDown
