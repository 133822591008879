// Libraries
import React, { useState, useEffect } from 'react'

// MaterialUI
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

// Components
import OtherTeamTable from '../components/OtherTeamTable'
import Loading from '../components/Loading'

// APIs
import {
  selectedTeamDataAPI,
  selectedRosterDataAPI,
} from '../adapters/teamsAPI'

const OtherTeam = () => {
  const classes = useStyles()
  const teamID = window.location.pathname.split('/team/')[1]
  const isRegTeam =
    teamID !== '1' && teamID !== '2' && teamID !== '30' ? true : false

  const [values, setValues] = useState({
    teamInfo: {},
    teamRoster: [],
    forwards: [],
    defenses: [],
    goalies: [],
    reserves: [],
    farms: [],
    drafts: [],
    isLoading: false,
  })

  useEffect(async () => {
    setValues({ ...values, isLoading: true })

    // GET selected team info
    const teamInfoData = await selectedTeamDataAPI(teamID)

    // GET selected team's roster info
    const rosterData = await selectedRosterDataAPI(teamID)

    setValues({
      ...values,
      forwards: rosterData.forwards,
      defenses: rosterData.defenses,
      goalies: rosterData.goalies,
      reserves: rosterData.reserves,
      farms: rosterData.farms,
      drafts: rosterData.drafts,
      teamInfo: teamInfoData,
      teamRoster: rosterData,
      isLoading: false,
    })
  }, [teamID])

  return (
    <>
      {values.isLoading ? (
        <Loading />
      ) : (
        <>
          <Typography>{values.teamInfo.teamName}</Typography>

          {/* Do not show here if it's Waivers, FA or Retired */}
          {isRegTeam && (
            <Typography className={classes.metaInfo}>
              General Manager: {values.teamInfo.firstName}{' '}
              {values.teamInfo.lastName} {values.teamInfo.email}
            </Typography>
          )}

          <OtherTeamTable
            title="Forwards"
            players={values.forwards}
            isRegTeam={isRegTeam}
          />
          <OtherTeamTable
            title="Defense"
            players={values.defenses}
            isRegTeam={isRegTeam}
          />
          <OtherTeamTable
            title="Goalies"
            players={values.goalies}
            isRegTeam={isRegTeam}
          />
          <OtherTeamTable
            title="Reserves"
            players={values.reserves}
            isRegTeam={isRegTeam}
          />
          <OtherTeamTable
            title="Farms"
            players={values.farms}
            isRegTeam={isRegTeam}
          />

          {/* Do not show here if it's Waivers, FA or Retired */}
          {isRegTeam && (
            <div>
              <Typography className={classes.title}>Draft Picks</Typography>
              <ul className={classes.draftList}>
                {values.drafts.map((ticket) => (
                  <li key={ticket.playerID}>{ticket.playerName}</li>
                ))}
              </ul>
            </div>
          )}
        </>
      )}
    </>
  )
}

const useStyles = makeStyles({
  metaInfo: {
    borderTop: '1px solid black',
    borderBottom: '1px solid black',
    padding: '10px 0',
    margin: '10px 0 30px',
  },
  title: {
    textAlign: 'center',
  },
  draftList: {
    listStyle: 'none',
    padding: 0,
  },
})

export default OtherTeam
