// Libraries
import React, { useState, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'

// materialUI
import Avatar from '@material-ui/core/Avatar'
import Typography from '@material-ui/core/Typography'
import SportsHockeyTwoToneIcon from '@material-ui/icons/SportsHockeyTwoTone'
import { makeStyles } from '@material-ui/core/styles'

// Redux
import { useDispatch, useSelector } from 'react-redux'
import { getUserInfo } from '../reducks/users/selector'
import { getDraftData } from '../reducks/drafts/selector'
import { fetchDraftData } from '../reducks/drafts/operations'

// Custom Components
import { PageTitle, DraftCard } from '../components'
import { Button, DropDown } from '../components/UIkits'
import { draftYear } from '../adapters/helperAPI'
import { deleteDraft } from '../adapters/draftAPI'

const Drafts = () => {
  const classes = useStyles()

  // Redux
  const dispatch = useDispatch()
  const selector = useSelector((state) => state)
  const draftData = getDraftData(selector)
  const { draftID, winner, draftList } = draftData
  const userInfo = getUserInfo(selector)
  const { teamID, isAdmin } = userInfo
  const history = useHistory()

  const [selected, setSelected] = useState(undefined),
    [year, setYear] = useState(2021),
    [draftYearOption, setDraftYearOption] = useState([])

  useEffect(() => {
    dispatch(fetchDraftData(year))
    draftYear().then((res) => {
      setDraftYearOption(res)
    })
  }, [year])

  return (
    <div className={classes.outerDiv}>
      <PageTitle title="Drafts" />

      {isAdmin ? (
        <Link to="/adminDashboard/draft/edit">Create New Draft</Link>
      ) : (
        ''
      )}

      <DropDown
        label="Year"
        value={year}
        onChange={setYear}
        options={draftYearOption}
        className={classes.dropdown}
      />

      {draftYearOption.length === 0 ? (
        <Typography
          variant="h5"
          component="h2"
          align="center"
          className={classes.error}
        >
          Draft has not been created yet.
        </Typography>
      ) : (
        <>
          <div className={classes.winnerDiv}>
            <Avatar
              alt={winner.teamName}
              src={`https://res.cloudinary.com/tasteit/image/upload${winner.logoURL}`}
              className={classes.winnerLogo}
              variant="square"
            />
            <Typography variant="h5" component="h2" className={classes.winner}>
              <SportsHockeyTwoToneIcon />
              {`${year} Winner: ${winner.teamName}`}
            </Typography>
          </div>

          {draftList.map((round) => (
            <div className={classes.roundDiv}>
              <Typography variant="h5" component="h2">
                Round {round.round}
              </Typography>
              <Button
                variant="contained"
                onClick={() => {
                  dispatch(fetchDraftData(year))
                }}
              >
                Reload
              </Button>

              {round.list.map((row, index) => (
                <div className={classes.card}>
                  <DraftCard
                    draftPickID={row.draftPickID}
                    rank={index + 1}
                    originalTeam={row.abbreviation}
                    draftTeamID={row.teamID}
                    teamName={row.teamName}
                    logoURL={row.logoURL}
                    playerName={
                      row.playerName ? row.playerName : 'Not Selected'
                    }
                    position={row.position}
                    onClick={() => {
                      if (isAdmin || teamID === row.teamID)
                        setSelected(row.draftPickID)
                    }}
                    year={year}
                    isSelected={selected === row.draftPickID}
                    setSelected={setSelected}
                  />
                  {selected === row.draftPickID && (
                    <Button
                      className={classes.button}
                      onClick={() => setSelected(undefined)}
                      variant="contained"
                      color="secondary"
                    >
                      Close
                    </Button>
                  )}
                </div>
              ))}
            </div>
          ))}

          {isAdmin && (
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                deleteDraft(draftID)
                history.push('/adminDashboard')
              }}
            >
              Delete
            </Button>
          )}
        </>
      )}
    </div>
  )
}

const useStyles = makeStyles(() => ({
  button: {
    position: 'absolute',
    right: '20px',
    top: '50%',
    transform: 'translateY(-50%)',
  },
  card: {
    position: 'relative',
    width: '100%',
  },
  dropdown: {
    width: '200px',
    marginTop: '100px',
  },
  error: {
    margin: '100px 0',
  },
  outerDiv: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  roundDiv: {
    margin: '100px 0',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  winner: {
    textAlign: 'center',
    margin: '50px 0',
  },
  winnerDiv: {
    margin: '100px 0 0',
  },
  winnerLogo: {
    height: '100px',
    width: '100px',
    margin: '0 auto',
    overflow: 'visible',

    '& .MuiAvatar-img': {
      width: 'auto',
    },
  },
}))

export default Drafts
