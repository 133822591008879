const initialState = {
  drafts: {
    winner: { teamName: '', logoURL: '' },
    draftList: [
      { round: 1, list: [] },
      { round: 2, list: [] },
      { round: 3, list: [] },
    ],
  },
  helpers: {
    positions: [],
    inTeamStatuses: [],
  },
  leagues: {
    isSigningSuspended: false,
    isTradingSuspended: false,
    rosterLimits: {
      forwardsLimit: 0,
      defensesLimit: 0,
      goaliesLimit: 0,
      reservesLimit: 0,
      farmsLimit: 0,
      timeLimit: 0,
    },
  },
  teams: {
    teamRoster: [],
    forwards: [],
    defenses: [],
    goalies: [],
    reserves: [],
    farms: [],
    drafts: [],
  },
  users: {
    userID: '',
    userName: '',
    firstName: '',
    lastName: '',
    teamID: '',
    email: '',
    isAdmin: false,
    notification: 0,
  },
  playerRequests: { count: 0, list: [] },
}

export default initialState
