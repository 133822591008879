import { leagueStatusAPI, getRosterLimits } from '../../adapters/leaguesAPI'
import { fetchLeagueStatusAction, fetchRosterLimitAction } from './actions'

export const getLeagueStatus = () => {
  return (dispatch) => {
    return leagueStatusAPI().then((res) => {
      dispatch(fetchLeagueStatusAction(res))
    })
  }
}

export const fetchRosterLimits = () => {
  return (dispatch) => {
    return getRosterLimits().then((res) => {
      dispatch(fetchRosterLimitAction(res))
    })
  }
}
