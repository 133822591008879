import React, { useState } from 'react'

// materialUI
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import Link from '@material-ui/core/Link'
import Typography from '@material-ui/core/Typography'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import IconButton from '@material-ui/core/IconButton'
import Launch from '@material-ui/icons/Launch'
import RemoveCircleOutline from '@material-ui/icons/RemoveCircleOutline'

// Redux
import { useDispatch, useSelector } from 'react-redux'
import { changeInTeamStatus, dropPlayer } from '../reducks/players/operations'
import { getUserInfo } from '../reducks/users/selector'
import { rosterLimitsValues } from '../reducks/leagues/selector'

// Custom Components
import { Button, Modal } from './UIkits'

const YourTeamTable = (props) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const selector = useSelector((state) => state)
  const rosterLimits = rosterLimitsValues(selector)

  const { title, players } = props

  const [modalIsOpen, setModalIsOpen] = useState(false),
    [targetPlayer, setTargetPlayer] = useState()

  const userInfo = getUserInfo(selector)
  const { teamID } = userInfo

  const inTeamStatusList = [
    { inTeamStatusID: 1, inTeamStatus: 'Main Team' },
    { inTeamStatusID: 2, inTeamStatus: 'Reserve' },
    { inTeamStatusID: 3, inTeamStatus: 'Farm' },
  ]

  let capacityNumber

  switch (title) {
    case 'Forwards':
      capacityNumber = rosterLimits.forwardsLimit
      break
    case 'Defense':
      capacityNumber = rosterLimits.defensesLimit
      break
    case 'Goalies':
      capacityNumber = rosterLimits.goaliesLimit
      break
    case 'Reserves':
      capacityNumber = rosterLimits.reservesLimit
      break
    case 'Farms':
      capacityNumber = rosterLimits.farmsLimit
      break
    default:
  }

  return (
    <div className={classes.wrapper}>
      {/* Table Title */}
      <Typography
        className={`${classes.title} ${
          players.length > capacityNumber && classes.error
        }`}
      >
        {title}
        {` (${players.length}/${capacityNumber})`}
      </Typography>

      {/* Table */}
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Player</TableCell>
              <TableCell>Games</TableCell>
              <TableCell>Drop</TableCell>
              <TableCell>Change Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {players.map((player) => (
              <TableRow key={player.playerID}>
                <TableCell component="th" scope="row">
                  <Link
                    href={`http://www.hockeydb.com/ihdb/stats/pdisplay.php3?encode=TRUE&pid=${player.profileURL}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <IconButton>
                      <Launch className={classes.launchIcon} />
                    </IconButton>

                    {player.playerName}
                  </Link>
                </TableCell>
                <TableCell>{player.numberOfGames}</TableCell>
                <TableCell>
                  <IconButton
                    onClick={() => {
                      setModalIsOpen(true)
                      setTargetPlayer({
                        playerName: player.playerName,
                        playerID: player.playerID,
                      })
                    }}
                  >
                    <RemoveCircleOutline className={classes.deleteIcon} />
                  </IconButton>
                </TableCell>
                <TableCell>
                  {/* In-Team Status Dropdown */}
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <Select
                      labelId="teamDropdown-label"
                      id="teamDropdown"
                      label="Status"
                    >
                      {inTeamStatusList.map((option) => (
                        <MenuItem
                          key={option.inTeamStatusID}
                          value={option.inTeamStatus}
                          onClick={() => {
                            dispatch(
                              changeInTeamStatus(
                                player.playerID,
                                option.inTeamStatusID,
                                player.numberOfGames,
                                player.position
                              )
                            )
                          }}
                        >
                          {option.inTeamStatus}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Modal isOpen={modalIsOpen} setIsOpen={setModalIsOpen}>
        <Typography>
          You are about to drop `{modalIsOpen && targetPlayer.playerName}`.
        </Typography>

        <div>
          <Button
            variant="contained"
            onClick={() => {
              dispatch(dropPlayer(targetPlayer.playerID, teamID))
              setModalIsOpen(false)
            }}
            type="button"
            className={classes.button}
          >
            OK
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              setModalIsOpen(false)
              setTargetPlayer()
            }}
            type="button"
            className={classes.button}
          >
            Cancel
          </Button>
        </div>
      </Modal>
    </div>
  )
}

const useStyles = makeStyles({
  wrapper: {
    margin: '20px 0',
  },
  title: {
    margin: '20px 0',
    textAlign: 'center',
  },
  table: {
    minWidth: 650,
  },
  launchIcon: {
    marginRight: '10px',
  },
  deleteIcon: {
    color: 'red',
    cursor: 'pointer',
  },
  button: {
    margin: '2rem',
  },
  formControl: {
    margin: 10,
    minWidth: 150,
  },
  error: {
    color: 'red',
  },
})

export default YourTeamTable
