import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { makeStyles } from '@material-ui/core/styles'

import { teamListAPI } from '../../adapters/helperAPI'

const TeamDropDown = (props) => {
  const { className, error, label, onChange, value, link, admin, name } = props

  const classes = useStyles()
  const [teamList, setTeamList] = useState([{ teamID: '', teamName: '' }])

  const stringifiedValue = JSON.stringify(value)

  useEffect(() => {
    // GET team list
    const getTeamList = async () => {
      await teamListAPI(admin).then((res) => {
        setTeamList(res)
      })
    }

    getTeamList()
  }, [])

  return (
    <FormControl
      variant="outlined"
      className={`${className} ${classes.outer}`}
      error={error}
    >
      <InputLabel id="dropdown-label">{label ? label : 'Teams'}</InputLabel>
      <Select
        labelId="dropdown-label"
        id="dropdown"
        label="Teams"
        name={name}
        onChange={onChange}
        value={stringifiedValue}
      >
        {link
          ? teamList.map((option) => (
              <Link
                to={
                  admin
                    ? `/adminDashboard/team/${option.teamID}`
                    : `/dashboard/team/${option.teamID}`
                }
                key={option.teamID}
              >
                <MenuItem value={option.teamID}>{option.teamName}</MenuItem>
              </Link>
            ))
          : teamList.map((option) => (
              <MenuItem
                key={option.teamID}
                value={JSON.stringify({
                  teamID: option.teamID,
                  teamName: option.teamName,
                })}
              >
                {option.teamName}
              </MenuItem>
            ))}
      </Select>
      <FormHelperText>{error}</FormHelperText>
    </FormControl>
  )
}

const useStyles = makeStyles({
  outer: {
    minWidth: '120px',
  },
})

export default TeamDropDown
