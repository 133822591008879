export const FETCH_LEAGUE_STATUS = 'FETCH_LEAGUE_STATUS'
export const fetchLeagueStatusAction = (leagueState) => {
  return {
    type: 'FETCH_LEAGUE_STATUS',
    payload: {
      isSigningSuspended: leagueState.signings,
      isTradingSuspended: leagueState.trades,
    },
  }
}

export const FETCH_ROSTER_LIMIT = 'FETCH_ROSTER_LIMIT'
export const fetchRosterLimitAction = (rosterLimit) => {
  return {
    type: 'FETCH_ROSTER_LIMIT',
    payload: {
      forwardsLimit: rosterLimit.forwardsLimit,
      defensesLimit: rosterLimit.defensesLimit,
      goaliesLimit: rosterLimit.goaliesLimit,
      reservesLimit: rosterLimit.reservesLimit,
      farmsLimit: rosterLimit.farmsLimit,
      timeLimit: rosterLimit.timeLimit,
    },
  }
}
