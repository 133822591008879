import React from 'react'

import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

import Line from './Line'

const PageTitle = (props) => {
  const classes = useStyles()

  const { title } = props

  return (
    <>
      <Line />
      <Typography className={classes.title}>{title}</Typography>
      <Line />
    </>
  )
}

const useStyles = makeStyles({
  title: {
    padding: '10px 0',
    margin: '10px 0 10px',
    textAlign: 'center',
  },
})

export default PageTitle
